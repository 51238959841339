@import "../styles/config";

.main-popup-window {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    @include flexDisplay();
    background: rgba(#000000, 0.7);
    z-index: 99;

    &.RTL {
        direction: rtl;
    }

    &.noToken {
        background: colors(clr1);
    }

    .outer-popup-window {
        width: 80vw;
        max-width: 640px;
        height: auto;
        max-height: 85vh;
        border-radius: 5px;
        box-shadow: 0px 0px 10px #1f0e4134;
        background-color: colors(back3);
        @include flexDisplay(column);
        overflow: hidden;
        justify-content: flex-start;
        align-items: flex-start;

        &.sml {
            max-width: 330px;
        }

        &.team {
            .inner-scroll-bar {
                flex-direction: column !important;
            }
        }

        .formiks,
        .create-post,
        .create-status {
            width: 100%;
            height: 100%;
            overflow: hidden;
            @include flexDisplay(column);
            justify-content: flex-start;
            align-items: flex-start;

            .content {
                width: 100%;
                height: 100%;
                @include flexDisplay(column);
                justify-content: flex-start;
                align-items: flex-start;
                overflow: hidden;
                position: relative;

                .inner-scroll-bar {
                    padding: 0.5rem;
                    align-items: center;
                }
            }

            .bottom {
                width: 100%;
                padding: 0.5rem;
                @include flexDisplay();
                justify-content: space-evenly;

                button {
                    background: colors(clr1);
                    width: 130px;
                    border-radius: 5px;
                    height: 45px;
                    cursor: pointer;
                    overflow: hidden;
                    @include flexDisplay();
                    color: colors(back1);
                    @include font(1rem);

                    &.close {
                        border: 1px solid;
                        background: none;
                        color: colors(red2);
                    }
                }
            }
        }
    }

    &.DRK .outer-popup-window {
        background-color: colors(clr3);
        box-shadow: 0 0 4px rgba(colors(clr2), 0.75);

    }

    @include media(phone) {
        .outer-popup-window {
            max-width: 100% !important;
            max-height: 100%;
            width: 100%;
            height: 100%;
            border-radius: 0px;
            box-shadow: none;
        }
    }
}

.image-popup-window {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    top: 0px;
    left: 0px;
    @include flexDisplay();

    .close-popup,
    .download-popup,
    .reset-popup {
        background: colors(back3);
        width: auto;
        height: auto;
        border-radius: 5px;
        cursor: pointer;
        z-index: 2;
        @include flexDisplay();
        position: fixed;
        top: 1rem;
        right: 1rem;
        padding: 0.47rem;
    }

    .close-popup {
        background: colors(red2);
        padding: 0.6rem;

        svg {
            width: 16px !important;
            height: 16px !important;
        }
    }

    .download-popup {
        right: 62px;
    }

    .reset-popup {
        right: 108px;
        padding: 0.46rem;
        background: colors(clr2);
    }

    .overlay-fillter {
        position: absolute;
        background: colors(back3) no-repeat center;
        width: 150%;
        height: 150%;
        background: #f2f2f2 no-repeat center;
        background-size: cover;
        filter: blur(3rem) brightness(0.75);

        &.black {
            background-image: none !important;
            background: colors(clr1) no-repeat center;
        }
    }

    .outer-img-preview {
        z-index: 1;
        display: flex;

        & > div {
            max-width: 100vw;
            max-height: 100vh;
            border-radius: 5px;
            display: flex;
            overflow: unset !important;

            img {
                max-width: 100vw;
                max-height: 100vh;
                border-radius: 5px;
                display: flex;
            }
        }

        &.avatar > div {
        }
    }

    .arrow-ctr {
        background: colors(back3);
        width: auto;
        height: auto;
        border-radius: 5px;
        cursor: pointer;
        z-index: 2;
        padding: 1rem 0.25rem;
        position: absolute;
        @include flexDisplay();
        opacity: 0.7;

        &.right {
            left: auto;
            right: 0.5rem;

            svg {
                transform: rotate(-90deg);
            }
        }

        &.left {
            right: auto;
            left: 0.5rem;

            svg {
                transform: rotate(90deg);
            }
        }
    }
}

.close-popup-window {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    @include flexDisplay();
    background: rgba(#000000, 0.3);
    z-index: 100;

    &.RTL {
        direction: rtl;
    }

    &.mini {
        background-color: colors(clr1);
    }

    .close-popup {
        width: 300px;
        height: auto;
        border-radius: 5px;
        padding: 1rem;
        box-shadow: 0px 0px 10px #1f0e4134;
        background-color: colors(back3);
        @include flexDisplay(column);

        &.minipopup {
            width: 400px;

            a {
                color: colors(blu);
                @include font(1rem);
            }
        }

        span {
            padding: 0;
            @include flexDisplay();
            color: colors(red2);
            @include font(1rem);
        }

        & > div {
            padding: 0;
            @include flexDisplay(column);
            justify-content: flex-start;
            color: colors(clr1);
            @include font(1rem);

            &.error {
                color: colors(red2);
            }

            &.correct {
                color: colors(clr2);
            }
        }

        .outer-button {
            width: 100%;
            margin-top: 1rem;
            @include flexDisplay();
            justify-content: space-evenly;

            button {
                width: auto;
                height: 40px;
                padding: 0px 1rem;
                border-radius: 5px;
                cursor: pointer;
                @include flexDisplay();
                color: colors(back1);
                background: colors(clr1);
                @include font(0.8rem);

                &.close {
                    border: 1px solid;
                    background: none;
                    color: colors(red2);
                }

                &:last-child {
                    margin: 0px;
                }
            }
        }
    }

    &.DRK {
        .close-popup {
            background-color: colors(clr3);
            &.minipopup {
                color: colors(back3);
            }

            & > div {
                color: colors(back3);

                &.error {
                    color: colors(red2);
                }

                &.correct {
                    color: colors(clr2);
                }
            }
        }
    }
}
