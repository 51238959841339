@import "../../../../styles/config";

.window-posts {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    z-index: 1;

    .posts-outer {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        @include flexDisplay(column);
        align-items: flex-start;
        position: relative;

        .inner-scroll-bar {
            @include flexDisplay(row-reverse);
            align-items: flex-start;
            justify-content: flex-start;
            position: relative;

            .left-section-post {
                width: 100%;
                @include flexDisplay(column);
                align-items: flex-start;
                justify-content: flex-start;

                .noData-yet.color {
                    margin-top: 0px;
                }
            }

            .right-section-post {
                width: 100%;
                @include flexDisplay();
                position: relative;
                z-index: 1;
            }
        }
    }

    @include media(laptop) {
        padding-right: 0rem;
    }

    // @include media(phone) {
    //     height: calc(100% - 50px) !important;
    // }

    @at-root {
        .RTL .window-posts {
            @include media(laptop) {
                padding-left: 0rem;
            }
        }
    }
}
