@import "../../styles/config";

.window-changed.profile {
    width: 100%;
    height: 100%;
    background: colors(back3);
    @include flexDisplay(column !important);
    justify-content: flex-start;
    align-items: flex-start;

    .usersection {
        width: 100%;
        height: auto;
        padding: 1.25rem 0;
        transition: all 0.2s ease-in-out;
        @include flexDisplay(column);
        justify-content: flex-start;
        background: colors(back3);
        position: relative;
        $usersection: &;

        &.none {
            display: none;
        }

        .frontsection {
            width: 100%;
            position: relative;
            @include flexDisplay(column);

            .frontimg {
                width: 100%;
                height: 100%;
                max-width: 300px;
                min-width: 250px;
                max-height: 300px;
                min-height: 250px;
                z-index: 2;
                position: relative;
                border-radius: 50%;
                background: colors(back1);
                @include flexDisplay();
                cursor: pointer;

                .owner-badge {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    overflow: hidden;
                    @include flexDisplay();
                    transform: rotate(200deg) scale(1.2);
                    pointer-events: none;
                }

                .img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    overflow: hidden;
                    @include flexDisplay();
                }

                .badge {
                    position: absolute;
                    bottom: -1rem;
                    box-shadow: 0px 0px 2px #00000036;
                    border-radius: 50%;
                    background-color: colors(back1);
                    @include flexDisplay();
                    z-index: 1;
                    cursor: pointer;

                    &.owner {
                        box-shadow: none;
                    }

                    .owner-badge {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        overflow: hidden;
                        @include flexDisplay();
                        transform: rotate(20deg) scale(1.25);
                        pointer-events: none;
                        z-index: 1;
                    }

                    .inner {
                        cursor: pointer;
                        width: 47px;
                        height: 47px;
                        min-width: 47px;
                        max-width: 47px;
                        min-height: 47px;
                        border-radius: 50%;
                        overflow: hidden;
                        position: relative;
                        @include flexDisplay();
                        background: none;
                        padding: 0.25rem;

                        img {
                            position: relative;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .infosection-sml {
                color: colors(clr1);
                width: 100%;
                padding-top: 3rem;
                @include flexDisplay(column);
                justify-content: flex-start;

                &.top {
                    padding-top: 0rem;
                    margin-bottom: 3rem;
                    color: colors(clr2);

                    .info {
                        @include font(1.2rem);
                        font-weight: 600;
                        text-transform: uppercase;
                        color: #e1a63a;
                    }
                }

                .info {
                    padding: 0rem 0.5rem;
                    width: 100%;
                    @include flexDisplay();

                    h1 {
                        color: colors(clr1);
                        max-width: 250px;
                        @include font(1.25rem);
                        text-transform: none;
                    }

                    svg {
                        @include flexDisplay();
                        margin: 0 0.25rem;
                    }

                    &.sml {
                        h1 {
                            @include fontLit(0.9rem);
                            color: colors(clr2);
                        }
                    }
                }
            }
        }
    }

    .aboutsection {
        width: 100%;
        background: colors(back3);
        @include flexDisplay();
        align-items: flex-start;
        justify-content: center;

        .outer-info {
            width: 100%;
            max-width: 500px;
            height: auto;
            background-color: colors(back3);
            @include flexDisplay();
            @include font(1rem);
            padding: 1rem;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            flex-flow: row wrap;

            .info {
                width: 200px;
                max-width: 300px;
                min-width: 140px;
                @include flexDisplay();
                justify-content: flex-start;
                margin-bottom: 1rem;
                flex-grow: 1;

                &:last-child {
                    margin-bottom: 0rem;
                }

                svg {
                    padding: 0.2rem;
                    @include flexDisplay();
                    box-shadow: 0px 0px 4px #1f0e4147;
                    margin-right: 0.7rem;
                    border-radius: 4px;
                    min-width: 25px;
                    $svg: &;

                    @at-root {
                        .RTL #{$svg} {
                            margin-right: 0rem;
                            margin-left: 0.7rem;
                        }
                    }
                }

                span {
                    color: colors(clr1);
                    @include font(0.85rem, 1.25);
                    word-break: break-word;

                    @include media(tablet) {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
