@import "../../../../styles/config";

.create-post {
    .createpost-content {
        width: 100%;
        padding: 0.5rem;
        @include flexDisplay();
        justify-content: space-between;

        .user-info {
            @include flexDisplay();
            justify-content: flex-start;

            .user-img {
                width: 45px;
                height: 45px;
                position: relative;
                border-radius: 50%;
                padding: 2px;
                box-shadow: 0 0 1px 2px colors(clr2);
                @include flexDisplay();
                $user-img: &;

                img {
                    border-radius: 50%;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                    background-color: colors(back1);
                }

                @at-root {
                    .DRK #{$user-img} {
                        box-shadow: 0 0 1px 2px colors(back3);
                    }
                }
            }

            .user-name {
                margin-left: 0.5rem;
                color: colors(clr1);
                @include flexDisplay(column);
                align-items: self-start;

                $username: &;

                h1 {
                    @include font(0.9rem, 1.5);
                    margin-bottom: 0.25rem;
                }

                @at-root {
                    .RTL #{$username} {
                        margin-left: 0rem;
                        margin-right: 0.5rem;
                    }

                    .DRK #{$username} {
                        color: colors(back3);
                    }
                }
            }

            .page-info {
                width: auto;
                padding: 0.25rem 0.5rem;
                border-radius: 5px;
                @include font(0.8rem, 1);
                color: colors(back1);
                background-color: colors(clr2);
                box-shadow: 0px 0px 4px #1f0e4113;
                overflow: hidden;
                text-transform: uppercase;
            }
        }

        .outer-more {
            width: auto;
            height: auto;
            @include flexDisplay();
            position: relative;

            .outer-more-added {
                width: auto;
                max-width: 100px;
                height: 30px;
                border-radius: 15px;
                background: colors(back1);
                @include flexDisplay();
                justify-content: space-evenly;
                position: relative;
                box-shadow: 0 0 4px #1f0e413b;
                $added: &;

                .adder {
                    @include flexDisplay();
                    border-radius: 5px;
                    background: none;
                    width: 30px;
                    height: 30px;
                    // margin: 0px 0.4rem;
                    cursor: pointer;
                }

                &.close,
                &.secure {
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    margin-left: 0.5rem;

                    .adder {
                        svg {
                            width: 20px !important;
                            height: 20px !important;
                        }
                    }
                }

                &.secure {
                    width: 30px;
                    height: 30px;

                    .adder {
                        svg {
                            width: 22px !important;
                            height: 22px !important;
                        }
                    }

                    .open-more-win {
                        top: 2.5rem;
                        min-width: 100px;
                    }
                }

                @at-root {
                    .RTL #{$added} {
                        &.close,
                        &.secure {
                            margin-left: 0rem;
                            margin-right: 0.5rem;
                        }
                    }
                }

                @at-root {
                    .DRK #{$added} {
                        background: colors(clr1);

                        &.secure svg {
                            fill: colors(back3);
                        }

                        .open-more-win {
                            background: colors(clr2) !important;
                        }
                    }
                }
            }
        }
    }

    .content {
        position: relative;

        .backs-view-all {
            width: 100%;
            height: 100%;
            position: absolute;
            background: colors(clr1);
            z-index: 15;

            .close-backs {
                width: auto;
                width: 35px;
                height: 35px;
                border-radius: 25px;
                background: colors(back1);
                @include flexDisplay();
                position: absolute;
                top: 0rem;
                left: 50%;
                transform: translate(-50%);
                cursor: pointer;
                box-shadow: 0 0 4px #1f0e414d;
            }

            .backgrounds {
                @include flexDisplay();
                align-items: flex-start;
                align-content: flex-start;
                justify-content: flex-start;
                flex-wrap: wrap;
                flex-flow: row wrap;

                .one-back {
                    width: 200px;
                    height: auto;
                    min-width: 140px;
                    border-radius: 15px;
                    margin: 0.25rem;
                    background-color: colors(clr1);
                    box-shadow: 0px 0px 2px #000000b3;
                    @include flexDisplay(column);
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-grow: 1;
                    overflow: hidden;
                    cursor: pointer;

                    img {
                        width: 100%;
                    }

                    @include media(phone) {
                        width: 140px;
                        height: auto;
                    }
                }
            }
        }
    }

    .bottom {
        flex-direction: column !important;

        .outer-property {
            width: 100%;
            @include flexDisplay();
            justify-content: flex-start;
            border-radius: 5px;
            margin-bottom: 0.5rem;
            // padding: 0.25rem;
            $createpost: &;

            .property {
                width: auto;
                cursor: pointer;
                @include flexDisplay();
                justify-content: center;
                border-radius: 7px;
                padding: 0.25rem;
                margin: 0 0.25rem;
                background-color: colors(clr2);

                // .create {
                //     margin: 0 0.25rem;
                //     color: colors(back1);
                //     @include font(0.8rem);
                //     // border-radius: 5px;
                // }

                &.lit {
                    background-color: colors(back1);
                    box-shadow: 0 0 5px #1f0e415d;
                }

                &.disabled {
                    opacity: 0.5;
                }
            }

            // @at-root {
            //     .RTL #{$createpost} {
            //         .property {
            //             .create {
            //                 margin-right: 0.5rem;
            //                 margin-left: 0rem;
            //             }

            //             &:first-of-type {
            //                 margin-right: 0rem;
            //                 margin-left: 0.5rem;
            //             }
            //         }
            //     }
            // }
        }

        button {
            svg {
                margin: 0 0.25rem;
            }
        }
    }
}

.create-post .inner-scroll-bar {
    .ctm-background {
        width: auto;
        height: auto;
        top: 0px;
        position: sticky;
        background: colors(clr2);
        z-index: 4;
        padding: 0.25rem 0;
        @include flexDisplay();
        border-radius: 20px;
        margin-bottom: 0.5rem;

        .close-backs {
            min-width: 27px;
            width: 27px;
            height: 27px;
            border-radius: 25px;
            background: colors(back1);
            @include flexDisplay();
            position: relative;
            cursor: pointer;
            box-shadow: 0 0 4px #1f0e414d;
            margin: 0 0.25rem;
        }

        .button-ctm {
            width: auto;
            height: 27px;
            border-radius: 15px;
            background: colors(back1);
            @include flexDisplay();
            position: relative;
            padding: 0 0.5rem;
            margin: 0 0.25rem;

            svg {
                margin: 0 0.25rem;
            }

            &.post {
                cursor: pointer;
                text-transform: uppercase;
                background: colors(clr1);
                .max-char {
                    color: colors(back1);
                    @include fontLit(1rem, 24px);
                }
            }
        }

        .max-char {
            color: colors(clr2);
            @include fontLit(1rem, 25px);
        }
    }

    .outer-text {
        width: 100%;
        height: auto;
        position: relative;
        @include flexDisplay();

        &.background {
            border-radius: 5px;

            img {
                width: 100%;
                border-radius: 5px;
                @include flexDisplay();
                text-align: center;
                color: colors(clr1);
                z-index: 0;
            }

            .form-grid {
                max-height: 100%;
                height: auto;
                position: absolute;

                .textarea {
                    min-height: auto;
                    background: none;
                    width: 100%;
                    max-height: 100%;
                    height: auto;
                    padding: 0 1rem;
                    padding: 1rem 2rem;
                    color: #fff;
                    @include fontLit(1.45rem, 2.5rem);
                    text-align: center;
                    color: colors(clr1);
                    overflow: hidden;

                    &._1,
                    &._6,
                    &._7,
                    &._8 {
                        color: #1d1d1b;
                    }

                    &._2,
                    &._4,
                    &._9 {
                        color: #fffdf1;
                    }

                    &._3,
                    &._10 {
                        color: #fdeff0;
                    }

                    &._5 {
                        color: #9a4c31;
                    }

                    &._11 {
                        color: #ca545e;
                    }

                    &._12,&._13 {
                        color: #587771;
                    }
                    &._14 {
                        color: #FF5039;
                    }

                    @include media(phone) {
                        padding: 0.5rem 1rem;
                        @include fontLit(1rem, 2rem);
                    }
                }
            }
        }
    }

    .form-grid {
        width: 100%;
        height: auto;
        position: relative;
        @include flexDisplay(column);
        justify-content: flex-start;
        align-items: flex-start;
        z-index: 1;
        $form-grid: &;

        .textarea {
            width: 100%;
            min-height: 300px;
            border-radius: 5px;
            padding: 0.25rem 0.5rem;
            background: colors(back1);
            @include fontLit(1rem, 25px);
            color: colors(clr1);
            z-index: 1;
            white-space: pre-line;
            text-transform: initial !important;
            resize: none;

            &.errorHolder {
                color: colors(red2);
            }

            &.ltr {
                text-align: left;
            }

            &.rtl {
                text-align: right;
            }
        }

        @at-root {
            .DRK #{$form-grid} {
                .textarea {
                    background: colors(clr1);
                    color: colors(back3);
                }
            }
        }
    }

    .emoji-meetoor-picker {
        width: 100% !important;
        max-height: 0px;
        overflow: hidden;
        position: sticky;
        bottom: 0px;
        margin: auto;
        z-index: 3;
        direction: ltr;

        &.show {
            max-height: none;
            margin-top: 0.5rem !important;
        }
    }

    .overlay-imgs {
        width: 100%;
        height: auto;
        @include flexDisplay();
        position: relative;
        margin-top: 0.5rem;
        flex-wrap: wrap;
        flex-flow: row wrap;

        .one-img {
            width: auto;
            height: auto;
            @include flexDisplay();
            position: relative;
            background: rgba(colors(clr2), 0.35);
            margin: 0.25rem;
            padding: 0.25rem;
            border-radius: 5px;
            overflow: hidden;

            .close-popup {
                background: colors(clr1);
                width: 30px;
                border-radius: 5px;
                height: 30px;
                cursor: pointer;
                @include flexDisplay();
                position: absolute;
                top: 5px;
                right: 5px;
                padding: 0.2rem;
                z-index: 10;
            }

            img {
                width: auto;
                max-width: 100%;
                height: auto;
                border-radius: 5px;
                overflow: hidden;
                max-height: 250px;
            }
        }
    }
}
