//////////////////
$colors: (
    back1: #ffffff,
    back2: #c5c8d4,
    back3: #f2f2f2,
    clr1: #040F16,
    clr2: #267b77,
    clr3: #202429,
    gry: #f0f0f0,
    blu: #86bcff,
    red1: #e64c3c,
    red2: #D7443E,
    yel: #ffd900,
    online: #00eb62,
    offline: "#e64c3c",
    gld: #e1a63a,
);
// 202124

// 1e212d
// 24292e git

// 0E0F19 x
//080705 x
//040F16

//clr1 020202
//clr3 1A2026

// $colors: (
//     back1: #1b2036,
//     back2: #585a61,
//     back3: #151827,
//     clr1: #ffffff,
//     clr2: #267b77,
//     gry: #f0f0f0,
//     blu: #86bcff,
//     red1: #e64c3c,
//     red2: #D7443E
// );

//1c2137

@function colors($key) {
    @return map-get($colors, $key);
}

@mixin backgroundDrk($color: colors(clr1)) {
    background: $color url(../img/mat.png) repeat !important;
}

// @mixin backgroundLit($color: colors(back1)) {
//     background: $color url(../img/mat2.png) repeat !important;
// }

@mixin flexDisplay($dir: row) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: $dir;
}

@mixin font($size: 2em, $line: 1.4, $wit: 500) {
    font-family: GroldRounded, TajawalReg;
    font-size: $size;
    font-weight: $wit;
    font-stretch: normal;
    font-style: normal;
    line-height: $line;
    letter-spacing: normal;
}

@mixin fontLit($size: 1em, $line: 1.3) {
    font-family: GroldLight, TajawalReg;
    font-size: $size;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: $line;
    letter-spacing: normal;
}

@mixin scrollbar($color: transparent) {
    scrollbar-color: $color transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $color;
        border-radius: 5px;
    }
    &::-o-scrollbar {
        width: 6px;
    }
    &::-o-scrollbar-track {
        background: transparent;
    }
    &::-o-scrollbar-thumb {
        background-color: $color;
        border-radius: 5px;
    }
}

@mixin media($size) {
    @if ($size== "custom") {
        @media screen and (max-width: 1200px) {
            @content;
        }
    }
    @if ($size== "laptop") {
        @media screen and (max-width: 1024px) {
            @content;
        }
    }
    @if ($size== "tablet") {
        @media screen and (max-width: 770px) {
            @content;
        }
    }
    @if ($size== "phone") {
        @media screen and (max-width: 530px) {
            @content;
        }
    }
}
