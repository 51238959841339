@import "../../../../styles/config";

.window-posts .posts-outer .left-section-post {
    .float-refresh {
        width: auto;
        padding: 0.25rem;
        background-color: colors(clr2);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -50px;
        z-index: 50;
        transition: none;
        border-radius: 50%;
        @include flexDisplay(column);

        &.propa {
            width: 180px;
            height: 18px;
            top: 40px;
            border-radius: 9px;
        }
    }

    .create-post {
        width: 100%;
        height: auto;
        background-color: colors(back3);
        overflow: hidden;
        border-radius: 5px;
        padding: 0.25rem;
        @include flexDisplay(column);
        justify-content: flex-start;
        transition: all 200ms ease-in-out;
        margin-bottom: 1rem;
        position: relative;
        cursor: pointer;

        $createpost: &;

        &.ctm {
            @include flexDisplay();
        }

        .outer-post {
            width: 100%;
            height: auto;
            @include flexDisplay();
            justify-content: flex-start;
            align-items: flex-start;
            border-radius: 5px;
            padding: 0.25rem;
            background-color: colors(back1);

            .user-img {
                width: 40px;
                height: 40px;
                position: relative;
                border-radius: 50%;
                box-shadow: 0 0 1px 2px colors(clr2);
                padding: 0.1rem;
                @include flexDisplay();
                background-color: colors(back3);

                img {
                    border-radius: 50%;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                }
            }

            .create {
                margin-left: 0.5rem;
                color: colors(clr2);
                @include fontLit(0.9rem);
                white-space: pre-line;
            }
        }

        .outer-property {
            width: 100%;
            @include flexDisplay();
            justify-content: space-evenly;
            border-radius: 5px;
            margin-top: 0.25rem;

            .property {
                width: 50%;
                @include flexDisplay();
                justify-content: center;
                border-radius: 5px;
                padding: 0.25rem;
                background-color: colors(back1);

                .create {
                    margin-left: 0.5rem;
                    color: colors(clr2);
                    @include font(0.8rem);
                }

                &:first-of-type {
                    margin-right: 0.25rem;
                }
            }
        }

        @at-root {
            .RTL #{$createpost} {
                .outer-post {
                    .create {
                        margin-right: 0.5rem;
                        margin-left: 0rem;
                    }
                }

                .outer-property {
                    .property {
                        .create {
                            margin-right: 0.5rem;
                            margin-left: 0rem;
                        }

                        &:first-of-type {
                            margin-right: 0rem;
                            margin-left: 0.25rem;
                        }
                    }
                }
            }

            .DRK #{$createpost} {
                background-color: colors(clr3);
                .outer-post {
                    .user-img {
                        background-color: colors(clr3);
                    }
                    .create {
                        color: colors(back1);
                    }
                    background-color: colors(clr2);
                }

                .outer-property {
                    .property {
                        .create {
                            color: colors(back1);
                        }
                        background-color: colors(clr2);
                    }
                }

                &.dark {
                    background-color: rgba(colors(clr3), 0.9);
                }
            }
        }

        @include media(phone) {
            margin-bottom: 0.5rem;
            border-radius: 0px;
        }
    }

    .line-to-timeline {
        width: 100%;
        height: 3px;
        border-radius: 5px;
        margin-bottom: 1rem;
        transition: all 200ms ease-in-out;
        background-color: colors(clr2);

        @include media(phone) {
            margin-bottom: 0.5rem;
            border-radius: 0px;
        }
    }

    .mess-to-beFriends,
    .notes-for-posts {
        width: 100%;
        height: 40px;
        background-color: colors(back3);
        color: colors(clr1);
        overflow: hidden;
        border-radius: 5px;
        padding: 0 1rem;
        position: -webkit-sticky;
        position: sticky;
        top: -0.1px;
        margin-bottom: 1rem;
        @include font(0.95rem);
        @include flexDisplay();
        justify-content: flex-start;
        transition: all 200ms ease-in-out;
        z-index: 15;
        $beFriends: &;

        &.noSticky {
            position: relative;
        }

        &.dark {
            z-index: 15;
            background-color: rgba(colors(clr1), 0.9);
            color: colors(back3);
        }

        @at-root {
            .DRK #{$beFriends} {
                background-color: colors(clr3);
                color: colors(back3);
            }
        }

        @include media(phone) {
            margin-bottom: 0.5rem;
            border-radius: 0px !important;
        }
    }

    .post {
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
        padding: 0rem 0.5rem;
        position: relative;
        border-radius: 5px;
        background-color: colors(back3);
        @include flexDisplay(column);
        align-items: flex-start;
        justify-content: flex-start;
        color: colors(clr1);
        @include font(1rem);
        $post: &;

        &:last-child {
            margin-bottom: 0px;
        }

        &.pinned {
            width: calc(100% - 2px);
            border: colors(clr2) solid 1px;
            margin-left: 1px;
            margin-right: 1px;
        }

        &.cloned {
            padding: 0.25rem;
            padding-bottom: 0px;
            border-top: 1px solid darken(colors(back3), 4%);
            border-radius: 0px;
            margin-bottom: 0px;

            .info-content {
                margin-bottom: 0.75rem !important;
            }
        }

        .info-content {
            width: 100%;
            @include flexDisplay();
            justify-content: space-between;
            padding: 0.5rem 0;
            position: relative;
            margin-bottom: 1rem;
            border-bottom: 1px solid darken(colors(back3), 4%);

            .link {
                width: calc(100% - 35px);
                @include flexDisplay();
                justify-content: flex-start;

                .link_user {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .user-img {
                        width: 45px;
                        height: 45px;
                        min-width: 45px;
                        border-radius: 50%;
                        position: relative;
                        margin-right: 0.5em;
                        box-shadow: 0 0 1px 2px colors(clr2);
                        @include flexDisplay();
                        background-color: colors(back3);
                        $userimg: &;

                        &.owner {
                            box-shadow: none;

                            .owner-badge {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                overflow: hidden;
                                @include flexDisplay();
                                transform: scale(1.2);
                                pointer-events: none;
                            }
                        }

                        .img {
                            border-radius: 50%;
                            overflow: hidden;
                            width: 100%;
                            height: 100%;
                        }

                        .outerState {
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            border-radius: 50%;
                            overflow: hidden;
                            box-shadow: 0 0 0px 3px colors(back3);

                            .circle {
                                position: relative;
                                margin: 0px;
                            }
                        }

                        @at-root {
                            .RTL #{$userimg} {
                                margin-right: 0rem;
                                margin-left: 0.5rem;

                                .outerState {
                                    right: 0px;
                                    left: auto;
                                }
                            }

                            .DRK #{$userimg} {
                                .outerState {
                                    box-shadow: 0 0 0px 3px colors(clr3);
                                }
                            }
                        }
                    }

                    .user-info {
                        @include flexDisplay(column);
                        align-items: flex-start;
                        width: 220px;
                        $user-info: &;

                        .username,
                        .time-skill {
                            // width: 100%;
                            @include flexDisplay();
                            $username: &;

                            h1 {
                                color: colors(clr1);
                                text-transform: none;
                                @include font(0.9rem, 1.5);
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                max-width: 180px;

                                @include media(phone) {
                                    @include font(0.8rem, 1.3);
                                }
                            }

                            svg {
                                margin-right: 0.25rem;
                            }

                            @at-root {
                                .RTL #{$username} {
                                    svg {
                                        margin-left: 0.25rem;
                                        margin-right: 0rem;
                                    }
                                }

                                .DRK #{$username} {
                                    svg {
                                        fill: colors(back3);
                                    }
                                    h1 {
                                        color: colors(back3);
                                    }
                                }
                            }
                        }

                        div.skil {
                            text-transform: none;
                            @include fontLit(0.75rem, 1.25);
                            color: colors(clr2);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 140px;
                            white-space: nowrap;
                        }

                        .space {
                            margin: 0 0.2rem;
                            @include fontLit(0.75rem, 1.25);
                            color: colors(clr2);
                            overflow: hidden;
                        }

                        div.time {
                            @include font(0.75rem, 1.25);
                            color: colors(clr2);
                            position: relative;
                            @include flexDisplay();
                            justify-content: flex-start;
                            direction: ltr;
                            z-index: 5;
                            white-space: nowrap;

                            .time-hover {
                                width: auto;
                                position: absolute;
                                top: 20px;
                                padding: 0.4rem;
                                background-color: rgba(colors(clr1), 0.7);
                                @include fontLit(0.8rem);
                                color: colors(back3);
                                @include flexDisplay();
                                border-radius: 5px;
                                display: none;
                                white-space: nowrap;
                            }

                            &:hover {
                                .time-hover {
                                    display: flex;
                                }
                            }
                        }

                        .info-tag {
                            text-transform: none;
                            @include fontLit(0.8rem, 1.25);
                            color: colors(clr1);
                        }

                        @at-root {
                            .DRK #{$user-info} {
                                // div.skil {
                                //     color: colors(back3);
                                // }
                                div.time {
                                    color: colors(clr2);
                                }

                                .info-tag {
                                    color: colors(back3);
                                }
                            }

                            .RTL #{$user-info} {
                                div.time {
                                    justify-content: flex-end;
                                }
                            }
                        }
                    }
                }
            }

            button.more {
                width: 25px;
                height: auto;
                cursor: pointer;
                @include flexDisplay();
                @include font(1rem);
                margin-left: 0.5em;
                background: none;
                position: relative;
                $more: &;

                @at-root {
                    .RTL #{$more} {
                        margin-left: 0rem;
                        margin-right: 0.5rem;
                    }

                    .DRK #{$more} {
                        svg {
                            fill: colors(back3);
                        }
                    }
                }

                @include media(phone) {
                    svg {
                        width: 16px;
                        transform: rotate(90deg);
                    }
                }
            }

            .badges-post {
                bottom: -12px;
                left: 50%;
                transform: translate(-50%);
                position: absolute;
                width: auto;
                z-index: 1;
                background: rgba(colors(clr2), 0.75);
                @include flexDisplay();
                border-radius: 4px;

                .badge {
                    position: relative;
                    margin: 0.14rem;
                    @include flexDisplay();

                    .title {
                        width: auto;
                        min-width: 100%;
                        position: absolute;
                        top: 25px;
                        right: 0px;
                        padding: 0.4rem;
                        background-color: rgba(colors(clr1), 0.7);
                        @include fontLit(0.8rem);
                        color: colors(back3);
                        @include flexDisplay();
                        border-radius: 5px;
                        display: none;
                        white-space: nowrap;
                    }

                    &:hover .title {
                        display: flex;
                    }
                }
            }
        }

        .post-content {
            width: 100%;
            @include flexDisplay(column);
            color: colors(clr1);
            // margin-bottom: 1rem;
            position: relative;

            .post-text {
                width: 100%;
                @include fontLit(1rem);
                align-items: flex-start;
                justify-content: flex-start;
                white-space: pre-line;
                text-transform: none;
                word-break: break-word;
                position: relative;

                &.overflow {
                    max-height: 180px;
                    overflow: hidden;
                }

                &.morethan {
                    overflow: hidden;
                    max-height: 250px;
                }

                .show-more {
                    position: absolute;
                    bottom: 0rem;
                    width: 100%;
                    @include flexDisplay();
                    // padding: 0.25rem;

                    button {
                        padding: 0.1rem;
                        color: colors(back3);
                        background: rgba(colors(clr2), 0.75);
                        border-radius: 5px;
                        cursor: pointer;
                        @include flexDisplay();
                        width: 50px;
                    }
                }

                @include media(phone) {
                    @include fontLit(0.85rem);
                }

                &.none {
                    display: none;
                }

                .background {
                    width: 100%;
                    @include flexDisplay();
                    position: relative;

                    img {
                        width: 100%;
                        top: 0px;
                        left: 0px;
                        font-size: 1.6rem;
                        border-radius: 5px;
                        background-size: cover;
                        background-position: center center;
                        background-repeat: no-repeat;
                        @include flexDisplay();
                        text-align: center;
                        color: colors(clr1);
                        z-index: 0;
                    }

                    .ctm-text {
                        width: 100%;
                        max-height: 100%;
                        height: auto;
                        padding: 1rem 2rem;
                        color: #fff;
                        position: absolute;
                        @include font(1.5rem, 2.5rem);
                        text-align: center;
                        z-index: 1;
                        overflow: hidden;

                        &._1,
                        &._6,
                        &._7,
                        &._8 {
                            color: #1d1d1b;
                        }

                        &._2,
                        &._4,
                        &._9 {
                            color: #fffdf1;
                        }

                        &._3,
                        &._10,
                        &._15 {
                            color: #fdeff0;
                        }

                        &._5 {
                            color: #9a4c31;
                        }

                        &._11 {
                            color: #ca545e;
                        }

                        &._12,
                        &._13 {
                            color: #587771;
                        }
                        &._14 {
                            color: #ff5039;
                        }

                        .emoji-mart-emoji > span {
                            width: 38px !important;
                            height: 38px !important;
                        }

                        .meetoor_dir {
                            text-align: center !important;
                            line-height: 35px;
                        }

                        @include media(phone) {
                            padding: 0.5rem 1rem;
                            @include fontLit(1rem, 2rem);

                            .emoji-mart-emoji > span {
                                width: 28px !important;
                                height: 28px !important;
                            }
                        }
                    }
                }
            }

            .marTop {
                margin-top: 1rem;
            }

            .post-file {
                width: 100%;
                @include flexDisplay();
                border-radius: 5px;
                cursor: pointer;
                align-items: flex-start;
                max-height: 550px;
                overflow: hidden;

                img {
                    width: auto;
                    max-width: 100%;
                    border-radius: 5px;
                    box-shadow: 0 0 4px rgba(colors(clr1), 0.3);
                }

                &.marTop {
                    margin-top: 1rem;
                }
            }

            .post-code {
                margin-top: 1rem;
                width: 100%;
                @include flexDisplay();
                overflow: hidden;
                border-radius: 5px;
                padding: 0.5rem;
                background-color: colors(clr1);

                .temp-code {
                    width: 90%;
                    height: auto;
                    @include flexDisplay();
                    overflow: hidden;
                    border-radius: 5px;
                    padding: 1rem 0;

                    button {
                        cursor: pointer;
                        padding: 0.5rem 1rem;
                        @include fontLit(1rem);
                        background-color: colors(back3);
                        color: colors(clr1);
                        border-radius: 5px;
                        @include flexDisplay();
                    }
                }
            }
        }

        .react-content {
            width: 100%;
            @include flexDisplay();
            justify-content: flex-start;
            margin-bottom: 0;
            padding: 0.25rem 0;
            position: relative;
            z-index: 14;
            border-top: 1px solid darken(colors(back3), 4%);

            .like-section {
                width: auto;
                @include flexDisplay();
                border-radius: 50%;
                margin: 0 0.25rem;

                .like {
                    transition: all 0.25s ease-in-out;
                    width: 35px;
                    height: 35px;
                    min-width: 35px;
                    min-height: 35px;
                    border-radius: 50%;
                    cursor: pointer;
                    position: relative;
                    @include flexDisplay();
                    z-index: 1;
                    background: none;

                    svg {
                        transition: all 0.25s ease-in-out;
                        filter: drop-shadow(0px 1px 2px #1f0e417a);
                    }
                }

                &.add-3d {
                    svg {
                        animation: likeAnim 0.25s ease-in 1;
                    }
                }
            }

            // .other-section {
            //     width: 100%;
            //     max-width: 250px;
            //     margin: 0 0.25rem;
            //     @include flexDisplay(column);

            .likes-num-section {
                width: 100%;
                min-height: 18px;
                max-height: 19px;
                @include flexDisplay();
                justify-content: flex-start;
                color: colors(clr2);
                @include fontLit(0.75rem, 1);
                cursor: pointer;

                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            }

            .space-line {
                width: 100%;
                height: 1px;
                margin-top: 0.12rem;
                background-color: colors(clr2);
            }

            .comment-copy-section {
                width: 100%;
                min-height: 27px;
                max-height: 27px;
                @include flexDisplay();
                justify-content: space-between;
            }

            .number {
                margin: 0 0.25rem;
                background: none;
                @include flexDisplay();
                color: colors(clr2);
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

                &.add-3d {
                    animation: numAnim 0.25s ease-in 1;
                }

                .orignal {
                    @include font(0.9rem, 1.3);
                    @include flexDisplay();
                }
            }
            // }

            .comment-section {
                width: auto;
                @include flexDisplay();
                justify-content: flex-start;
                color: colors(clr2);
                @include font(0.8rem);
                border-radius: 5px;

                &.arrow_comment {
                    margin: 0 0.5rem;
                }

                .arrow {
                    cursor: pointer;
                    padding: 0.25rem;
                    border-radius: 5px;
                    background: none;
                    @include flexDisplay();

                    svg {
                        transition: all 0.2s ease-in-out;
                    }
                }

                .comment {
                    cursor: pointer;
                    border-radius: 5px;
                    @include font(0.9rem);
                    color: colors(clr1);
                    background: none;
                    @include flexDisplay();
                    transition: all 0.2s ease-in-out;
                }

                .slash {
                    width: 2px;
                    height: 18px;
                    margin: 0 0.2rem;
                    border-radius: 4px;
                    background-color: rgba(colors(clr2), 0.7);
                    @include flexDisplay();
                }
            }
        }

        &.no-margin {
            padding: 0.25rem;
            padding-bottom: 0px;

            .info-content,
            .post-content {
                margin-bottom: 0.1rem;
            }

            .info-content {
                padding: 0.25rem;
            }

            .post-content .post-text {
                padding: 0.5rem;
            }
        }

        .comment-content {
            width: 100%;
            margin-bottom: 0.5rem;
        }

        @at-root {
            .DRK #{$post} {
                background-color: colors(clr3);
                color: colors(back1);
                &.cloned {
                    border-top: 1px solid rgba(lighten(colors(clr1), 5%), 0.4);
                }
                .react-content {
                    border-top: 1px solid rgba(lighten(colors(clr1), 5%), 0.4);
                }
                .info-content {
                    border-bottom: 1px solid rgba(lighten(colors(clr1), 5%), 0.4);
                }

                .post-content {
                    color: colors(back1);

                    .post-file img {
                        box-shadow: 0 0 4px colors(clr1);
                    }
                }
            }
        }

        @include media(phone) {
            margin-bottom: 0.5rem;
            padding: 0rem 0.5rem;
        }
    }

    .update-top-posts {
        width: 100%;
        height: auto;
        padding: 0.5rem;
        border-radius: 5px;
        cursor: pointer;
        @include flexDisplay();
        justify-content: center !important;
        z-index: 15;
        // margin-bottom: 1rem;

        .buttons-main {
            width: 100%;
            border-radius: 5px;
            @include flexDisplay();
            padding: 0.5rem;
            background: none;
            color: colors(back1);
            cursor: pointer;
            @include fontLit(0.9rem, 1.2);
            box-shadow: 0 0 0 1px colors(clr2);
            // margin-left: 0.5rem;
            $but-main: &;

            &:nth-of-type(1) {
                margin-right: 0.25rem;
            }

            &:nth-of-type(2) {
                margin-left: 0.25rem;
            }

            &:nth-of-type(3) {
                margin-left: 0.5rem;
            }

            &.active {
                background-color: colors(clr2);
                box-shadow: none;
            }

            &.up {
                width: 33px;
                min-width: 33px;
                height: 33px;
                padding: 0rem;
                background-color: colors(back3);
                display: none;
                box-shadow: none;

                svg {
                    margin: 0rem;
                    transform: rotate(-90deg);
                }
            }

            .red-dot {
                animation: opacity 0.7s ease-in-out infinite alternate-reverse;
                top: auto;
            }

            svg {
                margin: 0 0.25rem;
            }

            @at-root {
                .RTL #{$but-main} {
                    margin: 0;
                    &:nth-of-type(1) {
                        margin-left: 0.25rem;
                    }

                    &:nth-of-type(2) {
                        margin-right: 0.25rem;
                    }

                    &:nth-of-type(3) {
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }

    @at-root {
        .RTL .window-posts .posts-outer .left-section-post {
            .loader {
                left: auto;
                right: 0px;
            }
        }
    }
}
