/* created By Ahmad Hasan */
/* @import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap"); */
/* @font-face {
    font-family: "TajawalReg";
    src: local("TajawalReg"), url(./fonts/TajReg.ttf);
} */
/* local('fontReg'),  */
/* @font-face {
    font-family: "TajawalLight";
    src: local("TajawalLight"), url(./fonts/tajLit.ttf);
} */
/* url(./fonts/tajReg.ttf); */
@font-face {
    font-family: "TajawalReg";
    src: url(./fonts/marai.ttf);
}
@font-face {
    font-family: "GroldRounded";
    src: url(./fonts/fontReg.otf);
}
/* local('fontReg'),  */
@font-face {
    font-family: "GroldLight";
    src: url(./fonts/fontLit.otf);
}

/* :root {
    --color: rgb(250, 250, 250);
    --background-color: #040F16;
    --link-color: rgb(0, 188, 212);
    --main-headline-color: rgb(233, 30, 99);
    --accent-background-color: rgb(0, 188, 212);
    --accent-color: #040F16;
} */

/* @font-face {
    font-family: "TajawalLight";
    src: url(./fonts/tajLit.ttf);
} */

/* defualt settings */
* {
    padding: 0px;
    margin: 0px;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    border: 0px;
    text-decoration: none;
    -webkit-touch-callout: default; /* displays the callout */
    -webkit-touch-callout: none;
    /* -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
    -o-tap-highlight-color: rgba(0, 0, 0, 0);
    scrollbar-color: #1f0e4130 #ffffff6b;
    scrollbar-width: thin;
    overflow-anchor: auto !important;

    /* -ms-touch-action: none;
    touch-action: none; */
    /* overscroll-behavior-y: contain; */
}

*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
*::-webkit-scrollbar-track {
    background: #ffffff6b;
}
*::-webkit-scrollbar-thumb {
    background-color: #1f0e4130;
    background-clip: padding-box;
}
*::-o-scrollbar {
    width: 6px;
    height: 6px;
}
*::-o-scrollbar-track {
    background: #ffffff6b;
}
*::-o-scrollbar-thumb {
    background-color: #1f0e4130;
    background-clip: padding-box;
}

.DRK *::-webkit-scrollbar-track {
    background: #040f16;
}

.DRK *::-webkit-scrollbar-thumb {
    background-color: #202124;
}

.DRK *::-o-scrollbar-track {
    background: #040f16;
}

.DRK *::-o-scrollbar-thumb {
    background-color: #202124;
}
/* *:not(input) {
} */

@media screen and (max-width: 600px) {
    *::-webkit-scrollbar {
        display: none;
    }
    *::-o-scrollbar {
        display: none;
    }
    * {
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
}

@-ms-viewport {
    width: device-width;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    outline: none !important;
}
/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

#meetoor-company {
    overflow: hidden;
    position: relative;
    z-index: 1;
    height: 100%;
}
/* input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
} */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

/* for body */
body {
    overflow-y: overlay;
}

.container {
    width: 85%;
    height: auto;
}

@keyframes likeAnim {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(2.4);
    }
}

@keyframes micWave {
    from {
        fill: #267b77;
    }
    to {
        fill: #1f0e41;
    }
}

@keyframes camStream {
    from {
        fill: #d7443e;
    }
    to {
        fill: #1f0e41;
    }
}

@keyframes wave {
    from {
        transform: scale(0);
        background-color: transparent;
    }
    to {
        transform: scale(1.05);
        background-color: #ff0800c7;
    }
}

@keyframes blur {
    from {
        background-color: transparent;
    }
    to {
        background-color: #ff0800c7;
    }
}

@keyframes opacity {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.5;
    }
}

@keyframes numAnim {
    from {
        opacity: 0.25;
        transform: scale(1.8);
        /* transform: rotateX(90deg); */
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes toast {
    from {
        opacity: 0;
        transform: translate(-50%, 5rem);
        /* transform: rotateX(90deg); */
    }
    to {
        opacity: 1;
        transform: translate(-50%, 0rem);
    }
}

@keyframes reactsAnim {
    0% {
        opacity: 0;
        transform: scale(0);
        left: 4rem;
        top: 5rem;
        /* transform: translateY(10rem); */
    }

    70% {
        opacity: 0.35;
        transform: scale(1.5);
        left: 0;
        top: 0;
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
