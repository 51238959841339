@import "../../styles/config";
// varbels

$htL: 80px;
$htT: 60px;
$htP: 50px;

.awlira-spacer {
    flex: 1;
}

.flexDisplay {
    @include flexDisplay();
}
// style for nav
.meetoor-nav {
    width: 100%;
    height: $htL;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 7;
    background-color: colors(back1);
    border-bottom: 1px solid colors(back3);
    transition: all 0.25s;
    @include flexDisplay();

    .container {
        @include flexDisplay();
        justify-content: space-between;

        .nav-logo {
            width: 130px;
            height: 45px;
            min-width: 130px;
            position: relative;
            overflow: hidden;
            @include flexDisplay();
            border-radius: 5px;
            background-color: colors(back1) !important;
            box-shadow: 0px 0px 5px #1f0e4138;

            svg {
                position: absolute;
            }

            @include media(laptop) {
                width: 90px;
                height: 40px;
                min-width: 90px;
                margin-right: 0.5rem;

                svg {
                    width: 80px;
                    height: 26px;
                }
            }

            @include media(phone) {
                width: 40px;
                height: 40px;
                min-width: 40px;
                margin-right: 1rem;

                svg {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .navList {
            width: auto;
            height: auto;
            @include flexDisplay();

            .nav-navigation {
                width: auto;
                height: auto;
                @include flexDisplay();

                .nav-link {
                    width: auto;
                    padding: 0 0.5rem;
                    margin: 0 0.5rem;
                    @include flexDisplay();
                    text-align: center;
                    position: relative;
                    top: 0px;
                    border: none;
                    background: none;

                    a {
                        width: 100%;
                        text-transform: capitalize;
                        letter-spacing: normal;
                        @include font(1rem, 1.29);
                        text-align: left;
                        color: colors(clr2);

                        &:hover,
                        &.active {
                            color: colors(clr1);
                            @include font(1rem, 1.29, 700);
                        }
                    }

                    &.signin a,
                    &.signup a {
                        border: 1px solid colors(clr2);
                        border-radius: 10px;
                        padding: 0.5rem 1rem;

                        &:hover {
                            color: colors(clr1);
                        }
                    }
                    &.signin a {
                        &:hover {
                            background-color: colors(clr2);
                            color: colors(back1);
                        }
                    }

                    &.signup a {
                        background-color: colors(clr1);
                        border: 1px solid colors(clr1);
                        color: colors(back1);

                        &:hover {
                            background: none;
                        }
                    }

                    @include media(laptop) {
                        padding: 0 0.25rem;
                        margin: 0 0.25rem;

                        a {
                            font-size: 1rem;
                        }
                    }

                    @include media(tablet) {
                        // width: 100%;
                        // height: auto;
                        // padding: 15px 20px;
                        // @include flexDisplay(column);
                        // align-items: flex-start;

                        // a {
                        //     padding: 0 15px;
                        //     color: colors(back1);
                        // }
                    }
                }

                @include media(tablet) {
                    display: flex;
                    width: 100%;
                    justify-content: flex-start;
                    align-items: self-end;
                    flex-direction: column;
                    background-color: colors(clr1);
                    height: 100%;
                    box-shadow: 1px 0px 3px #112233;

                    .nav-link {
                        width: 100%;
                        padding: 0.5rem 1rem;
                        // margin: 0.5rem 0;
                        @include flexDisplay();
                        justify-content: flex-start;
                        position: relative;
                        top: 0px;

                        &:first-child {
                            margin-top: 1rem;
                        }

                        a {
                            left: 0px;
                            transition: left 0.25s ease-in-out;
                            position: relative;
                            color: darken(colors(back3), 15%);

                            &:hover,
                            &.active {
                                color: colors(back1);
                            }
                            &.active {
                                left: 0.5rem;
                            }
                        }

                        &.signin a,
                        &.signup a {
                            border: none;
                            border-radius: 0;
                            padding: 0px;
                            color: colors(clr2);
                            width: 100%;

                            &:hover {
                                background: none;
                                color: colors(back1);
                            }
                        }
                    }
                }
            }

            .footer-meetoor {
                position: absolute;
                bottom: 0px;
                width: 100%;
                padding: 0.5rem 0;
                color: rgba(colors(back3), 0.35);
                @include fontLit(0.85rem, 1.3);
                @include flexDisplay();
                background-color: colors(clr1);

                span {
                    @include fontLit(0.75rem, 1.3);
                    color: rgba(colors(back3), 0.45);

                    &:last-child {
                        position: relative;
                        top: 2px;
                        color: rgba(colors(back3), 0.55);
                        @include fontLit(0.8rem, 1.3);
                    }
                }
            }

            @include media(tablet) {
                background-color: none;
                position: fixed;
                top: $htT;
                left: -110vw;
                width: 270px;
                height: calc(100vh - #{$htT});
                bottom: 0px;
                justify-content: flex-start;
                align-items: self-end;
                flex-direction: column;
                transition: 0.3s ease-in-out;

                &.active {
                    left: 0px;
                    z-index: 10;
                }
            }
            @include media(phone) {
                top: $htP;
                width: 100%;
                height: calc(100vh - #{$htP});
            }
        }

        .humber {
            background: none;
            cursor: pointer;
            height: auto;
            @include flexDisplay();
        }
    }

    @include media(tablet) {
        height: $htT;
    }
    @include media(phone) {
        height: $htP;
    }
}
