@import "../../styles/config";

.outer-load-post {
    position: relative;
    width: 100%;
    height: 300px;
}

.load-state-outer {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.load-pointSpread {
    position: relative;
    transition: all 0.3s ease-in-out;

    &.mrg {
        margin-bottom: 0.7rem;
    }
    &.mid {
        transform: scale(0.6);
    }
    &.mis {
        transform: scale(0.45);
    }
    &.sml {
        transform: scale(0.35);
    }

    &.abs {
        position: absolute;
    }
    &.absCustom {
        position: absolute;
        max-width: 100%;
        width: 100%;
        height: 100%;
        padding: 0px;
        left: 0px;
        top: 0px;
        background: colors(clr1);
        @include flexDisplay();
    }
}

.bage-load-more {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 40px;
    @include flexDisplay();

    &.back {
        background: rgba(colors(back3), 0.5);
    }
}

.loader-state {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    border-radius: 0px;
    background-color: colors(back3);
    @include flexDisplay(column);
    z-index: 1000;

    .loadtext {
        margin: 1rem 0;
        @include font(1.2rem);
    }
}

