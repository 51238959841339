@import "../../styles/config";
.meetoor-dashbord {
    @include flexDisplay(column);
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: colors(back1);

    &.RTL {
        direction: rtl;

        [alt="arrowLeft-ctr"] {
            transform: rotate(180deg) !important;
        }

        [alt="arrowRight-ctr"] {
            transform: rotate(180deg) !important;
        }

        .circle {
            margin-right: 0rem;
            margin-left: 0.5rem;
        }

        .notification-main-popup {
            left: 1rem;
            right: 0rem;
        }

        .dashbord-outer-tabs {
            transition: right 0.25s ease-in-out;

            @include media(tablet) {
                left: auto;
                right: -120% !important;
                box-shadow: -2px 0px 20px colors(clr1);
                &.active {
                    left: auto;
                    right: 0% !important;
                }
            }
        }

        .red-dot {
            left: auto;
            right: 15px;
        }
    }

    &.DRK {
        background-color: colors(clr1) !important;

        .continar-bottom {
            background-color: colors(clr1) !important;
        }

        .dashbord-outer-tabs {
            background-color: colors(clr3) !important;

            .dashbord-tabs-user {
                @include backgroundDrk(colors(clr3));
            }
        }
    }

    .continar-bottom {
        @include flexDisplay();
        justify-content: flex-start;
        align-items: flex-start;
        width: 100vw;
        position: relative;
        background-color: colors(back1);
        height: calc(100vh - 60px);
    }

    .dashbord-outer-tabs {
        width: 270px;
        min-width: 270px;
        height: 100%;
        position: relative;
        transition: left 0.25s ease-in-out;
        background-color: colors(clr1);
        z-index: 96;
        @include flexDisplay(column);
        justify-content: flex-start;
        align-items: center;

        .dashbord-tabs-user {
            width: 100%;
            height: auto;
            @include backgroundDrk(colors(clr1));
            @include flexDisplay(column);
            align-items: flex-start;

            .user {
                @include flexDisplay();
                justify-content: flex-start;
                width: 100%;
                margin: 1rem 0;
                padding-left: 1rem;
                $user: &;

                .user-img {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    padding: 0.1rem;
                    position: relative;
                    box-shadow: 0 0 1px 2px colors(clr2);
                    @include flexDisplay();
                    background-color: colors(back3);

                    img {
                        border-radius: 50%;
                        overflow: hidden;
                        width: 100%;
                        height: 100%;
                    }

                    .outerState {
                        position: absolute;
                        bottom: 4px;
                        right: 4px;
                        border-radius: 50%;
                        overflow: hidden;
                        box-shadow: 0 0 0px 5px colors(clr1);

                        .circle {
                            position: relative;
                            margin: 0px;
                        }
                    }
                }

                .user-info {
                    @include flexDisplay(column);
                    align-items: flex-start;
                    margin-left: 0.7rem;
                    width: calc(100% - 80px);

                    h1 {
                        color: colors(back1);
                        @include font(1.2rem, 1.3);
                        max-width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    p {
                        @include flexDisplay();
                        @include fontLit(0.8rem, 1.25);
                        font-weight: 300;
                        color: #b2b2b2;
                        max-width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;

                        span {
                            color: lighten(colors(clr2), 10%);
                        }
                    }
                }

                @at-root {
                    .RTL#{$user} {
                        padding-left: 0rem;
                        padding-right: 1rem;

                        .user-img .outerState {
                            right: auto;
                            left: 0px;
                        }

                        .user-info {
                            margin-left: 0em;
                            margin-right: 0.7rem;
                        }
                    }

                    .DRK#{$user} {
                        .user-img {
                            .outerState {
                                box-shadow: 0 0 0px 5px colors(clr3);
                            }
                        }

                        .user-info {
                            h1 {
                                color: colors(back3);
                            }

                            p span {
                                color: lighten(colors(clr2), 10%);
                            }
                        }
                    }
                }
            }
        }

        .outer-tabs-tab {
            width: 100%;
            height: 100%;
            @include flexDisplay();
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            position: relative;
            border-top: 1px solid colors(clr1);

            &.custom {
                height: auto;
                min-height: 40px;
            }

            .nav {
                width: 100%;
                height: auto;
                color: colors(back1);
                @include font(0.8rem, 1.3);
                @include flexDisplay();
                text-transform: uppercase;
                justify-content: flex-start;
                position: relative;
                padding: 1rem;
                transition: all 250ms ease-in-out;
                filter: brightness(0.8);
                $nav: &;

                &.active,
                &:hover {
                    background-color: lighten(colors(clr1), 5%);
                    color: colors(back1);
                    filter: none;
                }

                svg {
                    margin-right: 0.7rem;
                }

                &::before {
                    transition: all 300ms ease-in-out;
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: colors(clr2);
                    position: absolute;
                    right: 40px;
                    opacity: 0;
                }

                &.active::before {
                    opacity: 1;
                    right: 10px;
                }

                @at-root {
                    .RTL#{$nav} {
                        svg {
                            margin-left: 0.7rem;
                            margin-right: 0rem;
                        }

                        &::before {
                            right: auto;
                            left: 40px;
                        }

                        &.active::before {
                            right: auto;
                            left: 10px;
                        }
                    }
                }
            }
        }

        .footer-meetoor {
            width: 100%;
            height: 30px;
            min-height: 30px;
            color: rgba(colors(back3), 0.35);
            @include fontLit(0.85rem, 1.3);
            @include flexDisplay();
            direction: ltr;

            span {
                @include fontLit(0.75rem, 1.3);
                color: rgba(colors(back3), 0.45);

                &:last-child {
                    position: relative;
                    top: 2px;
                    color: rgba(colors(back3), 0.55);
                    @include fontLit(0.8rem, 1.3);
                }
            }
        }

        @include media(tablet) {
            height: calc(100vh - 60px);
            position: absolute;
            left: -120%;
            box-shadow: 2px 0px 20px colors(clr1);
            &.active {
                left: 0%;
            }
        }

        @include media(phone) {
            width: 100%;
            box-shadow: none;
        }
    }
    
    .notification-main-popup {
        @include flexDisplay(column);
        justify-content: flex-start;
        align-items: flex-start;
        width: 250px;
        height: auto;
        overflow: hidden;
        position: fixed;
        bottom: 0.5rem;
        right: 1rem;
        border-radius: 5px;
        z-index: 103;

        .notification-block {
            width: 100%;
            height: auto;
            border-radius: 5px;
            padding: 0.5rem;
            margin: 0.5rem 0;
            @include flexDisplay();
            background-color: colors(clr3);
            cursor: pointer;
            position: relative;
            justify-content: space-around;
            transition: background-color 0.15s ease-in-out;
            box-shadow: 0 0 2px colors(clr2);
            $notification-block: &;

            .notification-photo {
                width: 50px;
                height: 50px;
                box-shadow: 0 0 1px 2px colors(clr2);
                border-radius: 50%;
                padding: 0.1rem;
                position: relative;
                @include flexDisplay();

                & > img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }

                .notification-type {
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    overflow: hidden;
                    background-color: colors(clr1);
                    @include flexDisplay();
                    box-shadow: 0 0 0px 2px colors(clr3);
                    right: 0px;
                    bottom: -5px;
                    $type: &;

                    @at-root {
                        .RTL#{$type} {
                            left: 0px;
                            right: 0rem;
                        }
                    }
                }
            }

            .notification-content {
                @include flexDisplay(column);
                align-items: flex-start;
                width: calc(100% - 50px);
                height: auto;
                padding: 0 0.5rem;
                @include fontLit(0.75rem);
                color: #c5c8d4;
                position: relative;

                .user-name {
                    @include font(0.8rem);
                    color: colors(back1);
                    max-width: 150px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .target {
                    color: rgba(colors(back1), 0.7);
                    padding: 0 0.25rem;
                }

                .time {
                    color: lighten(colors(clr2), 10%);
                    z-index: 3;
                    position: relative;

                    .time-hover {
                        position: absolute;
                        top: 20px;
                        padding: 0.4rem;
                        background-color: colors(back1);
                        @include fontLit(0.75rem);
                        color: colors(clr1);
                        @include flexDisplay();
                        border-radius: 5px;
                        display: none;
                    }

                    &:hover {
                        .time-hover {
                            display: flex;
                        }
                    }
                }
            }
        }
    }

    .meetoor-main-toast {
        @include flexDisplay();
        width: auto;
        bottom: 2rem;
        left: 50%;
        transform: translate(-50%);
        border-radius: 15px;
        background: colors(clr2);
        position: absolute;
        z-index: 105;
        padding: 0.35rem;
        animation: toast 0.25s ease;
        animation-fill-mode: both;

        .toast-icon {
            margin: 0 0.25rem;
        }

        .toast-text {
            margin: 0 0.25rem;
            @include font(1rem, 25px);
            color: colors(back1);
        }
    }

    .trends-section {
        width: 100%;
        height: auto;
        @include flexDisplay(column);
        align-items: flex-start;
        margin-top: 0.1rem;

        &.main {
            background: none !important;
            max-width: 500px;
            margin: auto;

            .trends {
                padding: 0.5rem;
                margin-bottom: 0.5rem;
                border-radius: 5px;
                background: colors(clr3);

                .trend {
                    @include font(1rem, 1.5);
                    margin-bottom: 0.5rem;
                }

                .number {
                    @include font(0.85rem, 1.3);
                    opacity: 0.7;
                }
            }
        }

        .trends {
            width: 100%;
            @include flexDisplay(column);
            justify-content: flex-start;
            align-items: flex-start;
            background: colors(clr1);
            overflow: hidden;
            padding: 0.2rem;
            margin-bottom: 0.1rem;

            .trend {
                width: 100%;
                padding: 0 0.5rem;
                color: colors(clr2);
                @include font(0.9rem, 1.5);
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .number {
                color: colors(back3);
                @include font(0.75rem, 1.3);
                opacity: 0.6;
                padding: 0 1rem;
            }
        }
    }

    .red-dot {
        position: absolute;
        width: 12px;
        height: 12px;
        top: 10px;
        left: 15px;
        border-radius: 50%;
        background-color: colors(red1);
    }
}

.to-humber {
    width: 25px;
    height: 25px;
    padding: 0px;
    position: relative;
    @include flexDisplay(column);
    background: none;
    overflow: hidden;

    &::before,
    &::after {
        content: "";
        display: block;
        border-radius: 5px;
        width: 100%;
        height: 2px;
        background: none;
        position: relative;
        transition: all 0.15s ease-in;
        top: 0%;
        left: -100%;
    }
    &::after {
        left: 100%;
    }

    &.active {
        &::before,
        &::after {
            top: 50%;
            left: 50%;
            background-color: #ce0000;
            position: absolute;
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}

.livewave {
    position: relative;
    @include flexDisplay();
    justify-content: center;
    padding: 0 0.5rem;

    svg {
        margin: auto !important;
    }

    &::after {
        position: absolute;
        content: "";
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: rgba(colors(red1), 0.95);
        animation: opacity 0.75s linear 0s infinite alternate;
    }
}

.open-more-win {
    position: absolute;
    width: auto;
    min-width: 150px;
    max-width: 100%;
    height: auto;
    padding: 0.25rem;
    box-shadow: 0px 0px 5px #1f0e4138;
    right: 0rem;
    top: 3rem;
    border-radius: 5px;
    background-color: colors(back1);
    z-index: 15;
    $morewin: &;

    &.clone {
        right: auto !important;
        left: auto !important;
        top: auto;
        bottom: -0.5rem;
    }

    .item-more {
        width: 100%;
        height: auto;
        cursor: pointer;
        margin-bottom: 0.25rem;
        border-radius: 5px;
        background: none;
        color: colors(clr1);
        @include font(0.9rem, 1.3);
        @include flexDisplay(column);
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0.5rem;
        text-transform: capitalize;
        transition: all 250ms ease-in-out;

        .item {
            width: 100%;
            @include flexDisplay();
            justify-content: flex-start;

            svg {
                width: 20px;
                margin-right: 0.5rem;
            }
        }

        span {
            width: 100%;
            padding-left: 1.65rem;
            @include fontLit(0.75rem, 1.3);
            overflow: hidden;
            text-transform: lowercase;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: colors(clr2);
            $span: &;

            @at-root {
                .RTL #{$span} {
                    padding-left: 0rem;
                    padding-right: 1.65rem;
                }
            }
        }

        &:last-child {
            margin: 0px;
        }

        &:hover {
            box-shadow: 0px 0px 4px #1f0e411f;
        }

        &.del {
            color: colors(red2);
        }
    }

    .before {
        pointer-events: none;
        position: absolute;
        display: flex;
        right: 3px;
        top: -22px;
        border: 12px solid transparent;
        border-bottom-color: colors(back1);
    }

    &.chat {
        right: auto !important;
        left: 50% !important;
        top: 2rem !important;
        transform: translate(-50%);
        min-width: 200px;
        background-color: colors(back3) !important;
    }

    @at-root {
        .RTL #{$morewin} {
            right: auto;
            left: 0rem;

            .item-more .item svg {
                margin-left: 0.5rem;
                margin-right: 0rem;
            }

            .before {
                right: auto;
                left: 3px;
            }
        }

        .DRK #{$morewin} {
            background-color: colors(clr1);
            box-shadow: 0px 0px 4px colors(clr3);

            .item-more:not(.inner) {
                color: colors(back3);
                .item {
                    svg {
                        fill: colors(back3);
                    }
                }

                &:hover {
                    box-shadow: 0px 0px 3px colors(clr2);
                }
            }

            .before {
                border-bottom-color: colors(clr1);
            }
        }
    }
}

.circle {
    @include flexDisplay();
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 0.5rem;

    &.online {
        background-color: #00eb62;
    }

    &.offline {
        background-color: colors(red1);
    }

    &.way {
        background-color: #c3c8cf;
    }
}
