@import "../../../styles/config";
.dashbord-outer-header {
    width: 100%;
    height: 60px;
    position: relative;
    @include flexDisplay();
    justify-content: space-between;
    z-index: 99;
    box-shadow: 0 1px 2px rgba(colors(clr3), 0.2);

  

    .dashbord-tabs-logo {
        width: 270px;
        min-width: 270px;
        height: 60px;
        @include flexDisplay();
        justify-content: space-between;
        padding: 0 1rem;
        background-color: colors(back3);
    }

    .logo {
        width: 130px;
        height: 45px;
        min-width: 130px;
        position: relative;
        overflow: hidden;
        @include flexDisplay();
        border-radius: 5px;
        background-color: colors(back1) !important;
        box-shadow: 0px 0px 5px #1f0e4138;
        $logo: &;

        svg {
            position: absolute;
        }

        @include media(tablet) {
            width: 45px;
            height: 45px;
            min-width: 45px;

            span {
                display: none;
            }

            img {
                width: 30px;
            }
        }

        @include media(phone) {
            width: 36px;
            height: 36px;
            min-width: 36px;

            svg {
                width: 38px;
                height: 38px;
            }
        }
    }

    .dashbord-outer-inner {
        width: calc(100% - 270px);
        height: 60px;
        padding: 0 1rem;
        position: relative;
        @include flexDisplay();
        z-index: 10;
        background-color: colors(back3);

        .dashbord-inner-header {
            width: 100%;
            height: 100%;
            position: relative;
            @include flexDisplay();
            justify-content: space-between;
            $dashInner: &;

            .outer-header {
                width: 30px;
                height: 30px;
                @include flexDisplay();
                margin: 0 1.5rem;
                position: relative;
                $outer: &;

                &:first-child,
                &:last-child {
                    margin: 0px;
                }

                &.header-search {
                    width: 100%;
                    height: 40px;
                    @include flexDisplay();
                    align-items: flex-start;
                    margin: 0 0.5rem;

                    .search-ctr-phone {
                        background: none;
                        width: 35px;
                        height: 35px;
                        cursor: pointer;
                        position: relative;
                        @include flexDisplay();

                        svg {
                            position: absolute;
                        }

                        svg.hide {
                            opacity: 0;
                        }

                        @include media(phone) {
                            svg {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }

                    .search-input {
                        min-width: 200px;
                        max-width: 400px;
                        width: 100%;
                        height: auto;
                        max-height: 400px;
                        box-shadow: 0px 0px 5px #1f0e4138;
                        overflow: hidden;
                        position: relative;
                        border-radius: 10px;
                        background-color: colors(back1);
                        @include flexDisplay(column);
                        align-items: flex-start;
                        margin-bottom: 1rem;

                        label {
                            width: 100%;
                            height: 40px;
                            min-height: 40px;
                            border-radius: 10px;
                            overflow: hidden;
                            position: relative;
                            @include flexDisplay();
                            $one: &;

                            .searchIcon {
                                position: absolute;
                                background: none;
                                left: 10px;
                                cursor: pointer;
                                @include flexDisplay();
                            }

                            input.input {
                                position: relative;
                                width: 100%;
                                height: 100%;
                                padding-left: 40px;
                                padding-right: 10px;
                                color: #9b9b9b;
                                @include font(0.8rem, 1.3);
                                @include flexDisplay();
                            }

                            @at-root {
                                .RTL #{$one} {
                                    .searchIcon {
                                        left: auto;
                                        right: 10px;
                                    }
                                    input.input {
                                        padding-left: 10px;
                                        padding-right: 40px;
                                    }
                                }

                                .DRK #{$one} {
                                    .searchIcon {
                                        fill: colors(back3);
                                    }

                                    input.input {
                                        background-color: colors(clr1);
                                    }
                                }
                            }
                        }

                        .search-result {
                            width: 100%;
                            border-radius: 10px;
                            overflow: hidden;
                            position: relative;
                            background-color: colors(back3);
                            @include flexDisplay(column);
                            justify-content: flex-start;
                            align-items: flex-start;
                            align-content: flex-start;
                            display: none;

                            &.active {
                                display: flex;
                                border-radius: 0 0 10px 10px;
                            }

                            .inner-scroll-bar {
                                @include flexDisplay();
                                justify-content: flex-start;
                                align-items: flex-start;
                                align-content: flex-start;
                                flex-wrap: wrap;
                                padding-bottom: 0.5rem;
                            }

                            .sticy-filter {
                                width: 100%;
                                position: sticky;
                                height: 40px;
                                z-index: 10;
                                top: 0px;
                                background-color: colors(clr1);
                                @include flexDisplay();
                                justify-content: flex-start;

                                label {
                                    width: auto;
                                    height: 100%;
                                    overflow: hidden;
                                    position: relative;
                                    margin: 0rem;
                                    padding: 0 0.5rem;
                                    @include flexDisplay();
                                    @include font(0.85rem, 1.3);
                                    color: colors(back1);
                                }
                            }

                            .result-item,
                            .no-result {
                                width: 100%;
                                cursor: pointer;
                                height: auto;
                                margin: 0.5rem 0.5rem 0;
                                padding: 0.5rem 0;
                                background-color: #fafafaf5;
                                @include flexDisplay();
                                justify-content: flex-start;
                                border-radius: 5px;
                                box-shadow: 0px 0px 4px #1f0e410a;
                                $resulte: &;

                                &.room {
                                    flex-direction: column;

                                    .user-img {
                                        width: 70px;
                                        min-width: 70px;
                                        height: 70px;

                                        .type {
                                            right: -5px;
                                            bottom: -5px;
                                            background-color: colors(back1);

                                            svg {
                                                fill: colors(red1);
                                                padding: 0.25rem;
                                            }
                                        }
                                    }

                                    .user-info {
                                        h1 {
                                            @include font(1.2rem, 1.3);
                                        }
                                    }
                                }

                                .user-img {
                                    width: 45px;
                                    height: 45px;
                                    border-radius: 50%;
                                    position: relative;
                                    margin: 0 0.5em;
                                    box-shadow: 0 0 1px 2px colors(clr2);
                                    @include flexDisplay();

                                    &.owner {
                                        box-shadow: none !important;

                                        .owner-badge {
                                            position: absolute;
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 50%;
                                            overflow: hidden;
                                            @include flexDisplay();
                                            transform: scale(1.2);
                                            pointer-events: none;
                                        }
                                    }

                                    .img {
                                        border-radius: 50%;
                                        overflow: hidden;
                                        width: 100%;
                                        height: 100%;
                                    }

                                    .type {
                                        position: absolute;
                                        width: auto;
                                        height: auto;
                                        border-radius: 50%;
                                        overflow: hidden;
                                        background-color: colors(clr1);
                                        @include flexDisplay();
                                        box-shadow: 0 0 0px 2px colors(back3);
                                        right: -5px;
                                        bottom: -5px;

                                        svg {
                                            padding: 0.15rem;
                                        }
                                    }
                                }

                                .user-info {
                                    @include flexDisplay();

                                    h1 {
                                        color: colors(clr1);
                                        text-transform: none;
                                        @include font(1rem, 1.3);
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        max-width: 200px;
                                    }

                                    svg {
                                        margin-right: 0.25rem;
                                        $svg: &;

                                        @at-root {
                                            .RTL #{$svg} {
                                                margin-right: 0;
                                                margin-left: 0.25rem;
                                            }

                                            .DRK #{$svg} {
                                                fill: colors(back3);
                                            }
                                        }
                                    }
                                }

                                .trends {
                                    width: 100%;
                                    @include flexDisplay(column);
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    overflow: hidden;

                                    .trend {
                                        width: 100%;
                                        padding: 0 0.5rem;
                                        color: colors(clr2);
                                        @include font(0.9rem, 1.3);
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    .number {
                                        color: colors(clr1);
                                        @include font(0.75rem, 1.3);
                                        opacity: 0.6;
                                        padding: 0 1rem;
                                    }
                                }

                                &.no-result {
                                    padding: 0.5rem;
                                    background: none !important;
                                    color: colors(clr2);
                                    @include font(1.2rem, 1.3);
                                }

                                &:hover:not(.no-result) {
                                    background-color: colors(back1);
                                }

                                @at-root {
                                    .DRK #{$resulte} {
                                        .user-img {
                                            box-shadow: 0 0 1px 2px colors(clr2);

                                            .type {
                                                background-color: colors(clr3);
                                                box-shadow: 0 0 1px 2px colors(clr1);
                                            }
                                        }
                                        .user-info {
                                            h1 {
                                                color: colors(back3);
                                            }
                                        }

                                        .trends {
                                            .trend {
                                                color: colors(clr2);
                                            }

                                            .number {
                                                color: colors(back3);
                                            }
                                        }

                                        &:hover:not(.no-result) {
                                            background-color: colors(clr2);

                                            .trends {
                                                .trend {
                                                    color: colors(clr1);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    @include media(tablet) {
                        width: 35px;
                        height: 35px;
                        // margin: 0 1.5rem;

                        .search-input {
                            width: 95%;
                            position: fixed;
                            top: 55px;
                            left: 50%;
                            transform: translateX(-50%) scale(0);
                            transition: all 0.15s ease-in-out;

                            &.active {
                                transform: translateX(-50%) scale(1);
                            }
                        }
                    }

                    @include media(phone) {
                        width: 30px;
                        height: 30px;

                        .search-ctr-phone {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }

                &.header-other {
                    width: 50px;
                    min-width: 40px;
                    height: 50px;

                    @include media(tablet) {
                        width: 30px;
                        min-width: 30px;
                        height: 30px;
                    }
                }

                &.active .drop-float {
                    display: unset !important;
                }

                &.active:not(.user) {
                    .notify-all svg {
                        transform: scale(1.2);
                        fill: colors(clr2);
                    }
                }

                &.active.user {
                    button.user {
                        img {
                            transform: scale(1.2);
                            box-shadow: 0 0 5px colors(clr2);
                        }
                    }
                }

                .notify-all {
                    position: relative;
                    cursor: pointer;
                    @include flexDisplay();

                    span {
                        width: 20px;
                        height: 20px;
                        color: colors(back1);
                        background-color: #e64c3c;
                        border-radius: 50%;
                        position: absolute;
                        top: 0px;
                        right: -10px;
                        @include font(0.8rem, 1.3);
                        @include flexDisplay();
                    }

                    svg {
                        transition: all 0.25s ease-in-out;
                    }

                    @include media(phone) {
                        margin: 0px;

                        svg {
                            width: 22px;
                            height: 22px;
                        }
                    }
                }

                .header {
                    position: relative;
                    background: none;
                    cursor: pointer;
                    @include flexDisplay();

                    span {
                        @include fontLit(1rem, 1.25);
                        font-weight: 300;
                        color: colors(clr1);
                        margin-right: 0.5rem;
                        $span: &;

                        @at-root {
                            .RTL #{$span} {
                                margin-right: 0;
                                margin-left: 0.5rem;
                            }
                        }
                    }

                    &.friend {
                        overflow: hidden;
                        border-radius: 5px;
                        background-color: colors(clr1);

                        svg {
                            padding: 0.1rem;
                            overflow: hidden;
                            position: absolute;
                        }

                        &.active {
                            background: none;
                        }

                        .false {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    &.user {
                        img {
                            transition: all 0.25s ease-in-out;
                            width: 100%;
                            height: 100%;
                            padding: 1px;
                            box-shadow: 0 0 2px colors(clr2);
                            border-radius: 50%;
                            overflow: hidden;
                            background-color: colors(back3);
                        }
                    }

                    @include media(phone) {
                        margin: 0px;
                    }
                }

                &.for-friend {
                    display: flex;

                    svg.hide {
                        opacity: 0;
                    }
                }

                @include media(tablet) {
                    margin: 0.25rem !important;

                    &:first-child,
                    &:last-child {
                        margin: 0px !important;
                    }
                }

                @at-root {
                    .DRK #{$outer} {
                        &.header-search {
                            .search-input {
                                background-color: colors(clr1);

                                .search-result {
                                    background-color: colors(clr3);

                                    .result-item {
                                        background-color: colors(clr1);
                                    }
                                }
                            }
                            .search-ctr-phone svg {
                                fill: colors(back3);
                            }
                        }

                        .notify-all svg {
                            fill: colors(back3);
                        }

                        .header.friend {
                            background-color: colors(back1);

                            svg {
                                fill: colors(clr3);
                            }
                        }
                    }
                }
            }

            .replace {
                width: 30px;
                height: 30px;
                background: none;
                @include flexDisplay();
                cursor: pointer;
            }

            .live-meetoor-fromout {
                width: auto;
                height: auto;
                @include flexDisplay();
                margin-left: auto;
            }

            @at-root {
                .RTL #{$dashInner} {
                    .live-meetoor-fromout {
                        margin-right: 0;
                        margin-left: 1rem;
                    }
                }
            }
        }

        @include media(tablet) {
            width: 100%;
            padding: 0 0.5rem;
        }
    }

    .drop-float {
        transition: all 0s;
        position: fixed;
        top: 60px;
        border-radius: 15px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.48);
        width: auto;
        height: auto;
        background-color: colors(clr1);
        color: colors(back1);
        z-index: 99;
        display: none;

        &:not(.user) {
            @include media(phone) {
                right: auto !important;
                left: 50% !important;
                transform: translateX(-50%);
            }
        }

        &.user {
            $user: &;
            left: auto;
            right: 0px;

            @at-root {
                .RTL #{$user} {
                    right: auto;
                    left: 0px;
                }
            }
        }

        @include media(tablet) {
            &.notify {
                $notify: &;
                left: 0px;
                right: auto;

                @at-root {
                    .RTL #{$notify} {
                        right: 0px;
                        left: auto;
                    }
                }
            }
        }
    }

    @at-root {
        .DRK .dashbord-outer-header {
            box-shadow: 0 1px 2px rgba(colors(clr1), 0.5);

            .dashbord-tabs-logo,
            .dashbord-outer-inner,
            .dashbord-outer-calling {
                background-color: colors(clr3);
            }

            .logo {
                background-color: colors(clr1) !important;
            }
        }
    }
}
