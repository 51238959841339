@import "../styles/config";

$htL: 70px;
$htT: 60px;
$htP: 50px;

body {
    & > video {
        display: none !important;
    }
}

.App {
    width: 100vw;
    height: 100vh;
    position: relative;
    @include flexDisplay(column);
    justify-content: flex-start;
    align-items: flex-start;

    &.filter {
        filter: blur(0.7rem);
    }

    .meetoor-home-main {
        width: 100vw;
        overflow-x: hidden;

        .temp-inner-loader {
            position: relative;
            width: 100%;
            height: 600px;
            @include flexDisplay();
        }
    }

    .post-files {
        @include flexDisplay();
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-flow: row wrap;
        max-height: 500px;
        border-radius: 10px;
        overflow: hidden;

        .one-section {
            width: 250px;
            height: 250px;
            margin: 2px;
            position: relative;
            border-radius: 5px;
            @include flexDisplay();
            overflow: hidden;
            flex-grow: 1;
            cursor: pointer;
            background: colors(clr2);

            .no-action {
                width: 100%;
                height: 100%;
                z-index: 5;
                position: absolute;
                top: 0px;
                left: 0px;
            }

            img,
            .meetoor-video-player {
                width: 100%;
                vertical-align: middle;
            }

            img {
                min-width: 250px;
                min-height: 250px;
                border-radius: 5px;
            }

            .detecting-observer {
                position: absolute;
            }

            @include media(phone) {
                width: 150px;
                height: 150px;

                img {
                    min-width: 150px;
                    min-height: 150px;
                }
            }
        }

        .sticky-info-more {
            position: sticky;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 40px;
            cursor: pointer;
            @include flexDisplay();
            z-index: 10;
            padding: 0 2px;

            div {
                width: 100%;
                height: 100%;
                @include flexDisplay();
                background: rgba(colors(clr2), 0.5);
                @include font(1.4rem);
                color: colors(back1);
                border-radius: 5px;
                overflow: hidden;
            }

            svg {
                margin: 0 0.5rem;
            }
        }
    }

    .with-touch {
        width: auto;
        height: auto;
        @include flexDisplay();
    }
}

.imgback-meetoor {
    pointer-events: none;
    position: absolute;
    left: -20%;
    width: auto;
    opacity: 0.03;
    transform: rotate(15deg);

    &.reverse {
        left: auto;
        right: -20%;
        transform: rotate(-15deg);
    }

    &.bottom {
        bottom: 10%;
    }
}

.custom-text {
    &.clr2 {
        color: colors(clr2);
    }

    &.blu {
        color: colors(blu);
    }

    &.red2 {
        color: colors(red2);
    }

    &.gld {
        color: colors(gld);
    }

    &.yel {
        color: colors(yel);
    }

    &.online {
        color: colors(online);
    }

    &.size {
        @include font(1.4rem !important);
    }
}

.set-filter {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    @include flexDisplay(column);

    .message {
        width: auto;
        padding: 1rem;
        position: relative;
        text-transform: capitalize;
        @include fontLit(1rem);
        @include flexDisplay(column);

        .sucss,
        .error {
            box-shadow: 0 0px 5px 0 rgba(31, 14, 65, 0.2);
            padding: 0.7rem;
            color: #00a92a;
            background-color: #fff;
            border-radius: 4px;
        }

        .error {
            color: colors(red2);
            button {
                text-decoration: underline;
                padding: 0 0.4rem;
                color: colors(clr1);
                background: none;
                cursor: pointer;
            }
        }

        .error-close {
            border-radius: 4px;
            padding: 0.3rem;
            margin: 1rem;
            background-color: #ffffff;
            color: colors(red2);
            @include font(1.2rem, 1.2);
            box-shadow: 0 6px 10px 0 rgba(31, 14, 65, 0.2);
            cursor: pointer;
        }
    }
}

.link-view-url {
    color: colors(clr2);
    @include font(0.9rem);
    background: none;
    cursor: pointer;

    &.button {
        width: fit-content;
        background: colors(clr2);
        color: colors(back3);
        margin: 0.25rem 0;
        padding: 0.5rem;
        border-radius: 5px;
        @include flexDisplay();
        direction: ltr;
        $button: &;

        svg {
            margin-right: 0.5rem;
            fill: darken(colors(clr2), 20%);

            &:nth-of-type(2) {
                margin-left: 0.5rem;
                fill: darken(colors(back3), 15%);
            }
        }

        @at-root {
            .RTL #{$button} {
                svg {
                    // margin-right: 0rem;
                    margin-left: 0.5rem;

                    &:nth-of-type(2) {
                        margin-left: 0rem;
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }
}

.del-msg-chat {
    margin: 0 0.2rem;
    @include flexDisplay();
    min-height: 25px;

    svg {
        margin: 0 0.25rem;
    }

    span {
        @include fontLit(0.8rem, 1.2);
    }

    &.ops {
        opacity: 0.5;
    }
}

.meetoor_dir {
    line-height: 24px;
}

.outer-message {
    .link-view-url.button {
        margin: 0rem auto !important;
    }
}

.breakline {
    width: 100%;
    @include flexDisplay();

    &::after {
        content: "";
        width: 100%;
        max-width: 700px;
        height: 2px;
        border-radius: 4px;
        background: #8a8a8a41;
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-popper {
    z-index: 70 !important;
}

.emoji-mart-emoji {
    line-height: normal;
    max-width: 100%;
    vertical-align: middle;
    display: inline !important;
    margin-left: 1px !important;
    margin-right: 1px !important;
    pointer-events: none;
}

.emoji-mart {
    position: relative;
    border: none !important;
    background: none !important;

    .emoji-mart-emoji {
        pointer-events: all;
    }

    .emoji-mart-bar:last-of-type {
        position: absolute;
        width: 100%;
        top: 87px;
        border: none !important;

        .emoji-mart-preview {
            height: 20px;

            .emoji-mart-preview-emoji,
            .emoji-mart-preview-data {
                display: none;
            }
            .emoji-mart-preview-skins {
                right: 10px;
            }
        }
    }

    .emoji-mart-search {
        #emoji-mart-search-1 {
            background: colors(back3);
            color: colors(clr1);
            border: none !important;
            box-shadow: 0 0 3px rgba(colors(clr1), 0.3);
        }
    }

    .emoji-mart-scroll {
        height: 200px;

        .emoji-mart-category-label span {
            background: colors(back1) !important;
            color: colors(clr1);
        }
    }
}

.emoji-meetoor-picker {
    width: 100% !important;
    max-height: 0px;
    overflow: hidden;
    direction: ltr;
    border-radius: 5px;
    box-shadow: none;
    background: colors(back1);
    $emojimeetoor: &;

    &.show {
        margin-bottom: 0.25rem;
        max-height: none;
        box-shadow: 0 0 3px rgba(colors(clr1), 0.3);
    }

    @at-root {
        .DRK #{$emojimeetoor},
        #{$emojimeetoor}.ctm {
            background: colors(clr1);

            &.show {
                margin-bottom: 0.25rem;
                max-height: none;
                box-shadow: 0 0 2px 2px rgba(colors(clr1), 0.5);
            }

            .emoji-mart {
                .emoji-mart-emoji {
                    pointer-events: all;
                }

                .emoji-mart-bar {
                    border-bottom: 1px solid colors(clr3);
                }

                .emoji-mart-bar:last-of-type {
                    position: absolute;
                    width: 100%;
                    top: 87px;
                    border: none;

                    .emoji-mart-preview {
                        height: 20px;

                        .emoji-mart-preview-emoji,
                        .emoji-mart-preview-data {
                            display: none;
                        }
                        .emoji-mart-preview-skins {
                            right: 10px;
                        }
                    }
                }

                .emoji-mart-search {
                    #emoji-mart-search-1 {
                        background: colors(clr3) !important;
                        color: colors(back3);
                    }
                }

                .emoji-mart-scroll {
                    height: 200px;

                    .emoji-mart-category-label span {
                        background: colors(clr1) !important;
                        color: colors(back3);
                    }
                }
            }
        }

        #{$emojimeetoor}.ctm {
            &.show {
                box-shadow: 0 0 2px 1px rgba(colors(clr2), 0.5);
            }
        }
    }
}

.slider-arrow {
    background: none;
    cursor: pointer;

    img {
        width: 20px;
    }

    &:disabled {
        display: none;
    }
}

.noData-yet {
    width: 100%;
    border-radius: 5px;
    @include flexDisplay();
    height: 385px;
    color: colors(clr1);
    background: colors(back3);

    &.color {
        height: 320px;
        border-radius: 5px;
        margin-top: 1rem;
    }

    &.dark {
        width: 100%;
        height: calc(100vh - 94px);

        .nodata {
            @include flexDisplay(column);

            button {
                width: auto;
                padding: 0.5rem 1rem;
                cursor: pointer;
                border-radius: 5px;
                @include font(1.1rem);
                color: colors(back1);
                text-transform: capitalize;
                background-color: colors(clr1);
                margin: 1rem;
            }
        }
    }

    .nodata {
        @include flexDisplay(column);

        img {
            width: 25px;
            margin-right: 0.5rem;
        }

        span {
            @include font(1rem);
            text-transform: capitalize;
            @include flexDisplay();
        }
    }

    &.noData-dark {
        background: colors(clr3);
        color: colors(back3);
    }

    @at-root {
        .DRK .noData-yet {
            background: colors(clr3);
            color: colors(back3);
        }
    }
}

.fade-enter {
    transition: all 0.3s ease;
    opacity: 0.1;
    transform: scale(0.9);

    &.fade-enter-active {
        opacity: 1;
        transform: scale(1);
    }
}

.fade-exit {
    transition: all 0.3s ease;
    transform: scale(1);
    opacity: 1;

    &.fade-exit-active {
        opacity: 0.1;
        transform: scale(0.9);
    }
}

.main-loader {
    width: 100vw;
    height: 100vh;
    position: absolute;
    @include flexDisplay(column);
    background-color: colors(back3);

    svg:not(.imgback) {
        margin-bottom: 0.5rem;
    }

    h1 {
        margin-top: 0.5rem;
        color: colors(clr1);
        @include font(1.2rem, 1.25);
    }

    .imgback {
        pointer-events: none;
        position: absolute;
        bottom: -1rem;
        opacity: 0.03;
        transform: scale(3);

        @include media(phone) {
            transform: scale(1.3);
        }
    }

    &.DRK {
        background-color: colors(clr3);
        h1 {
            color: colors(back3);
        }
    }
}

.reactEasyCrop_Container {
    .reactEasyCrop_CropArea {
        border-radius: 50%;
        border-width: 2px;
    }
}

.meetoor_dir {
    &.ltr {
        text-align: left !important;
    }

    &.rtl {
        text-align: right !important;
    }
}

.loader-class-main {
    @include flexDisplay();
    width: 100%;
    height: 100%;

    &.abs {
        min-height: 200px;
        position: absolute;
    }
}

.nav-link {
    width: auto;
    padding: 0 0.5rem;
    margin: 0 0.5rem;
    @include flexDisplay();
    position: relative;
    top: 0px;
    text-transform: uppercase;
    letter-spacing: normal;
    @include fontLit(1.2em, 1.29);
    color: colors(clr2);

    &:last-child {
        margin-right: 1rem;
    }

    &.signin,
    &.signup {
        border: 1px solid colors(clr2);
        border-radius: 10px;
        padding: 0.5rem 1rem;

        &:hover {
            color: colors(clr1);
        }
    }

    &.signin {
        &:hover {
            background-color: colors(clr2);
            color: colors(back1);
        }
    }

    &.signup {
        background-color: colors(clr1);
        border: 1px solid colors(clr1);
        color: colors(back1);

        &:hover {
            background: none;
        }
    }
}

.from-mob {
    margin-top: 1rem !important;

    .alert {
        color: #710000 !important;
    }
}

.posts-outer {

    &.auto {
        max-width: 600px;
        margin: auto;
    }
}

.dashbord-fixed-home {
    position: relative;
    height: 100%;
    width: calc(100% - 270px);
    @include flexDisplay();
    justify-content: space-between;

    &.mini .window-changed {
        align-items: flex-start;
        .home-container,
        .branches-container {
            top: 0px;
            height: calc(100% - 44px) !important;

            @include media(phone) {
                height: calc(100% - 54px) !important;
            }
        }
    }

    .window-changed {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        @include flexDisplay();

        &.extend {
            width: 100%;
        }

        @include media(custom) {
            width: 100%;
        }

        .window-posts.from-notificaion {
            position: relative;
            width: 100%;
            max-width: 600px;
            height: 100% !important;
            padding: 1rem;
            @include flexDisplay();

            @include media(phone) {
                padding: 0rem;
            }
        }
    }

    .meetoor-audioRoom {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0px;
        left: 0px;
        @include flexDisplay();
        background: colors(back1);
        z-index: 5;
        $audioRoom: &;

        &.hide {
            height: 60px;
            background: none;

            .audio-room {
                background: none;
            }

            .top-audio-room {
                margin: 0;
            }

            .bot-audio-room {
                box-shadow: 0px 0px 2px colors(clr2);
            }
        }

        @at-root {
            .DRK#{$audioRoom} {
                background-color: colors(clr1);
            }
        }

        @include media(phone) {
            &.hide {
                height: 50px;
            }
        }
    }

    @include media(tablet) {
        width: 100%;
    }
}