@import "../../styles/config";

// style for header
.meetoor-footer {
    width: 100%;
    height: auto;
    // background-color: colors(clr1);
    @include backgroundDrk();
    @include flexDisplay(column);
    overflow: hidden;
    position: relative;

    &.RTL {
        direction: rtl;

        .link img {
            margin-right: 0px !important;
            margin-left: 0.5rem;
        }
    }

    .imgback-meetoor {
        opacity: 0.1;
        transform: rotate(0deg);
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
        padding: 2.5em 0;
        position: relative;

        .footer-links {
            width: 100%;
            @include flexDisplay();
            align-items: flex-start;
            justify-content: space-between;

            .links {
                width: 100%;

                h1 {
                    color: colors(back1);
                    text-transform: capitalize;
                    // text-align: left;
                    margin-bottom: 0.5em;
                    @include font(1.5em, 1.31);
                }

                .link {
                    width: 100%;
                    color: #627385;
                    text-transform: lowercase;
                    // text-align: left;
                    padding: 0.5em 0;
                    display: flex;
                    @include fontLit(0.9em, 1.29);
                    @include flexDisplay();
                    justify-content: flex-start;

                    img {
                        width: 25px;
                        height: 25px;
                        margin-right: 0.5rem;
                    }

                    &.privacy {
                        text-decoration: underline;
                        color: #9299a1;
                    }

                    &.contact {
                        position: relative;
                        width: 200px;
                        height: 35px;
                        border-radius: 5px;
                        background-color: colors(clr2);
                        @include flexDisplay();
                        overflow: hidden;
                        cursor: pointer;

                        .ex-mail {
                            width: 100%;
                            padding: 0 0.5rem;
                            @include fontLit(0.9em, 1.29);

                            text-align: center;
                            color: colors(back1);
                        }
                        
                        .sender {
                            width: 35px;
                            height: 35px;
                            min-width: 35px;
                            @include flexDisplay();
                            background-color: colors(clr2);
                        }

                        &.meeting {
                            border: none;
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                            text-align: center;
                            background-color: colors(clr2);
                            color: colors(back1);
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }

        @include media(tablet) {
            flex-direction: column;
            left: 0%;

            .footer-links {
                width: 100%;
                align-items: flex-start;
                flex-wrap: wrap;

                .links {
                    width: 100%;
                    max-width: 220px;
                    margin: 1em 0px;

                    h1 {
                        // text-align: left;
                        margin: 0px;
                    }

                    .link {
                        padding: 0.5em 0 0.5em 1em;
                    }
                }
            }
        }
    }

    .footer-copy {
        width: 100%;
        height: 40px;
        @include flexDisplay();
        max-width: max-content;
        @include fontLit(0.9rem, 1.25);
        // text-align: left;
        color: #627385;
    }

    @include media(tablet) {
        background-size: 25%;
    }
}
