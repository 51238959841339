@import "../../../styles/config";

.icons {
    $icons: &;

    &.close {
        width: 14px !important;
        height: 14px !important;
    }

    &.fllOps8 {
        fill-opacity: 0.8;
    }

    &.anim,
    .anim {
        animation: micWave 0.1s ease-in-out infinite alternate;
    }

    &.animCam,
    .animCam {
        animation: camStream 0.75s ease-in-out infinite alternate;
    }

    &.stk {
        fill: none;
        stroke: colors(clr1);
    }

    &.ops85 {
        opacity: 0.85;
    }

    &.grn {
        fill: colors(clr2);
    }

    &.blu {
        fill: colors(blu);
    }

    &.ops75 {
        opacity: 0.75;
    }

    &.ops65 {
        opacity: 0.65;
    }

    &.ops5 {
        opacity: 0.5;
    }

    &.ops3 {
        opacity: 0.3;
    }

    &.red {
        fill: colors(red2);
    }

    &.drk {
        fill: colors(clr1);

        line {
            stroke: colors(clr1);
        }
    }

    &.lit {
        fill: colors(back1);
        line {
            stroke: colors(back1);
        }
    }

    &.rot180- {
        transform: rotate(-180deg);
    }

    &.rot90- {
        transform: rotate(-90deg);
    }

    &.rot180 {
        transform: rotate(180deg);
    }

    &.rot90 {
        transform: rotate(90deg);
    }

    
    &.rot225 {
        transform: rotate(-225deg);
    }

    &.rev {
        transform: scaleX(-1);
    }

    &.MTR {
        .cls-2,
        .cls-3 {
            opacity: 0.85;
        }
        .cls-2,
        .cls-3 {
            fill: colors(clr2);
        }

        &.drk .cls-1 {
            fill: colors(clr1);
        }

        &.lit .cls-1 {
            fill: colors(back2);
        }
    }

    &.list {
        .hide {
            transition: all 0.2s ease-in-out;
            opacity: 1;
        }
        .Rib1,
        .Rib2 {
            transition: all 0.25s ease-in-out;
        }
        // translate(390px, -230px)
        &.humberAnim {
            .hide {
                opacity: 0;
            }
            .Rib1,
            .Rib2 {
                stroke: colors(red2);
            }
            .Rib1 {
                transform-origin: 0% 30%;
                transform: rotate(45deg);
            }
            .Rib2 {
                transform-origin: 3% 70%;
                transform: rotate(-45deg);
            }
        }
    }

    @at-root {
        .RTL #{$icons} {
            &.rot90- {
                transform: rotate(90deg);
            }

            &.rot90 {
                transform: rotate(-90deg);
            }
        }
    }
}
