@import "../../../../styles/config";

.suspense-tempPost {
    padding-right: 1rem;
    width: 100%;
    height: 100%;
    @include flexDisplay(column);
    position: relative;
    align-content: flex-start;
    justify-content: flex-start;

    @include media(laptop) {
        padding-right: 0rem;
    }

    @at-root {
        .RTL .suspense-tempPost {
            padding-right: 0rem;
            padding-left: 1rem;

            @include media(laptop) {
                padding-left: 0rem;
            }
        }
    }
}

.window-posts .posts-outer .left-section-post {
    .post-loader {
        animation: opacity 0.5s ease-in-out infinite alternate;
        .info-content {
            .link {
                $like: &;
                width: 100%;

                .link_user {
                    width: auto;
                    display: flex;
                }

                .user-img {
                    background-color: colors(back1);
                }

                .user-info {
                    h1,
                    div.skil {
                        height: 13px;
                        width: 150px;
                        margin-bottom: 0.25rem;
                        border-radius: 5px;
                        background-color: colors(back1);
                    }

                    div.time {
                        height: 13px;
                        width: 150px;
                        margin-bottom: 0.25rem;
                        border-radius: 5px;
                        background-color: colors(back1);
                    }
                }

                @at-root {
                    .DRK #{$like} {
                        .user-img {
                            background-color: colors(clr1);
                        }

                        .user-info {
                            h1,
                            div.skil,
                            div.time {
                                background-color: colors(clr1);
                            }
                        }
                    }
                }
            }

            button.more {
                &::before {
                    opacity: 1 !important;
                }
            }
        }

        .post-content {
            $post-content: &;
            .post-text {
                height: 30px;
                border-radius: 5px;
                background-color: colors(back1);
            }

            .post-file {
                margin-top: 1rem;
                height: 70px;
                border-radius: 5px;
                background-color: colors(back1);
                box-shadow: none !important;
            }

            @at-root {
                .DRK #{$post-content} {
                    .post-text {
                        background-color: colors(clr1);
                    }

                    .post-file {
                        background-color: colors(clr1);
                    }
                }
            }
        }

        .react-content {
            width: 100%;
            // @include flexDisplay();
            $react-content: &;
            .like-section,
            .comment-section {
                .like {
                    background-color: rgba(colors(back1), 0.5) !important;
                }
            }
        }
    }
}
