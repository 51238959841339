@import "../styles/config";

.scroll-bar {
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    &.vertical {
        overflow-x: hidden;
        overflow-y: auto;
        @include flexDisplay(column);
        justify-content: flex-start;
        align-items: flex-start;

        .detecting-observer {
            width: 100%;
            height: 1px;
            min-height: 1px;
            background: none;
            visibility: hidden;
            pointer-events: none;
        }

        .inner-scroll-bar {
            width: 100%;
            height: auto;
            @include flexDisplay(column);
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &.horizontal {
        overflow-x: auto;
        overflow-y: hidden;
        @include flexDisplay();
        justify-content: flex-start;
        align-items: flex-start;

        .detecting-observer {
            width: 1px;
            min-width: 1px;
            height: 100%;
            background: none;
            visibility: hidden;
            pointer-events: none;
        }

        .inner-scroll-bar {
            width: auto;
            height: 100%;
            @include flexDisplay();
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: unset !important;
            flex-flow: unset !important;
        }
    }
}
