@import "../../styles/config";
// @import url("https://cdn.plyr.io/3.5.2/plyr.css");

.meetoor-video-player {
    width: auto !important;
    position: relative;
    pointer-events: fill;
    cursor: default !important;
    // display: block !important;
    box-shadow: 0 0 4px rgba(colors(clr2), 0.25);
    direction: ltr !important;
    min-width: 350px;
    // max-width: 500px;
    max-height: none !important;
    border-radius: 5px;

    .detecting-observer {
        position: absolute;
        top: 50% !important;
    }

    .overlay-player-ctr {
        width: 100%;
        height: calc(100% - 40px);
        position: absolute;
        top: 0px;
        left: 0px;
        @include flexDisplay();
        cursor: pointer;

        .player-ctr {
            width: 40px;
            height: 40px;
            position: absolute;
            background-color: colors(clr2);
            @include flexDisplay();
            padding: 0.5rem;
            border-radius: 50%;
        }
    }

    &.audio {
        min-width: 270px;
        height: 32px;
        min-height: 32px;

        .plyr__controls {
            padding: 0px !important;
        }
    }

    &.viewer:not(.no-viewer) {
        position: relative;

        .zoom-viewer {
            position: absolute;
            top: 0px;
            right: 0px;
            padding: 0.3rem;
            cursor: pointer;
            background: rgba(colors(clr2), 0.7);
            border-radius: 0 0 0 5px;
            @include flexDisplay();
        }
    }

    &.no-viewer {
        .zoom-viewer {
            display: none;
        }
    }

    & > .plyr {
        width: 100%;
        border-radius: 5px;

        video {
            max-height: 550px;
        }

        &:-webkit-fullscreen video {
            max-height: none;
        }

        &:-moz-fullscreen video {
            max-height: none;
        }

        &:fullscreen video {
            max-height: none;
        }

        .plyr__control--overlaid {
            background: colors(clr2);
        }

        // .plyr__control {
        input[type="range"] {
            color: colors(clr2) !important;
            min-width: 40px;
        }

        .plyr__control:hover {
            background: colors(clr2);
        }

        .plyr__control[aria-expanded="true"] {
            background: colors(clr2);
        }

        .plyr__volume input[type="range"] {
            min-width: 40px;
        }

        .plyr__progress {
            margin-left: 0px !important;
            margin-right: 15px !important;
        }
    }

    &.norm-chat {
        min-width: 100%;
    }

    // &.ref-chat {
    //     min-width: 100%;
    // }

    @include media(phone) {
        min-width: 300px;
    }
}
